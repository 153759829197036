// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    SW: true,
    url_gestor: 'https://gch-rel.0s.cl/',
    password_documentos:'oticsofofa-documentos',

    REGION: 'us-east-1',
    IDENTITY_POOL_ID: '',
    COGNITO_IDP_ENDPOINT: '',
    URL: 'https://reportes.gch-rel.0s.cl',
    URL_SIGNOUT: 'https://sistemasotic.0s.cl/logout',
    URL_AUTH: 'https://reportes.gch-rel.0s.cl/auth',
    USER_POOL_ID: 'us-east-1_FKBbyRPjB',
    CLIENT_ID: '3tcdd29p4ous7fphpshkc5f9o3',
    COGNITO_IDENTITY_ENDPOINT: '',
    STS_ENDPOINT: '',

    ENDPOINTS: {
        ACCION_CAPACITACION: 'https://gch.gw.api.rel.0s.cl/acciones-capacitacion',
        COMUNES: 'https://rep.gw.api.rel.0s.cl/reportes-gch-comunes-rep/comunes',
        GESTION_USUARIOS : 'https://mc.gw.api.rel.0s.cl/mantenedor-cliente/gestionUsuarios',
        CLIENTE_COMERCIAL: 'https://rep.gw.api.rel.0s.cl/reportes-gch-cliente-comercial/clienteComercial',
        NOTIFICACIONES: 'https://rep.gw.api.rel.0s.cl/reportes-gch-notificaciones/notificaciones',
        APORTES: 'https://rep.gw.api.rel.0s.cl/reportes-gch-aportes/aportes',
        CARTOLA: 'https://rep.gw.api.rel.0s.cl/reportes-gch-cartola/cartola',
        CONSOLIDADO: 'https://rep.gw.api.rel.0s.cl/reportes-gch-consolidado/consolidado',
        PERFIL_ROL: 'https://gch.gw.api.rel.0s.cl/perfil-roles',
    },
  API_DOMAINS: {
    CENTRO_NOTIFICACIONES: 'https://gch.gw.api.rel.0s.cl/centro-notificacion/',
    WS_URL: 'wss://soc.api.gch.0s.cl/ws'
  },
  CATENA_USR: 'https://serv.sistemasotic.0s.cl/servicio',
  KEYACCIONES:'VQYdjIY7PM55McFB5Utqh4iRyqHANBPD21PwtZiw',
  URLACCIONES:'https://auditoria.0s.cl/aud/registrar'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
