<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Resumen Cartola</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Resumen Cartola</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12">
                            <label>Selecciona una o varias Empresas y/o Sucursales y el Período a consultar, luego presiona la tecla Enter o el botón con el icono de Lupa:</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="row">
                                <div class="col-12 form-group">
                                    <label>Empresas y Sucursales:</label>
                                    <select class="form-control" placeholder="Selecciona" data-toggle="collapse" data-target="#buscarEmpresas" (keydown)="filtrarReporteKeydown($event)"></select>
                                    <span style="position:absolute;margin-top:-28px;margin-left:15px;z-index:9999;">
                                        {{empresasSucursalSeleccionadas.length === 0 ? 'Selecciona' : empresasSucursalSeleccionadas.length === 1 ? '1 Cliente Sucursal seleccionado' : empresasSucursalSeleccionadas.length + ' Clientes Sucursales seleccionados'}}
                                    </span>
                                </div>
                                <div class="collapse mx-auto" id="buscarEmpresas" style="border:1px solid #ccc;margin-top:-16px;width:95.5%;border-radius:5px;min-height:100px;transition:1s ease all;">
                                    <div class="row p-2">
                                        <div class="col-12 form-group">
                                            <label>Buscar Empresas y/o Sucursales:</label>
                                            <input [(ngModel)]="searchTerm" #busqueda (keyup)='onkeyup(busqueda.value)' type="text" class="form-control" placeholder="Ingresa RUT o Nombre para filtrar">
                                        </div>
                                        <kendo-treeview [nodes]="parsedData" [children]="children" [hasChildren]="hasChildren" textField="text" [kendoTreeViewCheckable]="checkableSettings" [(checkedKeys)]="checkedKeys" (checkedChange)="handleChecking($event)">
                                        </kendo-treeview>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="errorFiltro.empresaSucursal" class="form-error">
                                Debes seleccionar al menos una Empresa o Sucursal.
                            </span>
                        </div>

                        <!-- <div class="col-12 col-md-5">
                            <div class="row">
                                <div class="col-12 form-group">
                                    <label>Filtros:</label>
                                    <select class="form-control" placeholder="Selecciona" data-toggle="collapse" data-target="#filtrar"></select>
                                </div>
                                <div class="collapse mx-auto" id="filtrar" style="border:1px solid #ccc;margin-top:-16px;width:95.5%;border-radius:5px;min-height:100px;transition:1s ease all;">
                                    <div class="row p-2">
                                        <kendo-treeview [nodes]="parsedFilterData" [children]="children" [hasChildren]="hasChildren" textField="text" [kendoTreeViewCheckable]="checkableSettings">
                                        </kendo-treeview>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="errorFiltro.empresaSucursal" class="form-error">
                                Debes seleccionar al menos una Empresa o Sucursal.
                            </span>
                        </div> -->

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Desde:</label>
                            <kendo-datepicker class="form-control" placeholder="Ingresa" (valueChange)="actualizarFechaFin($event)" [min]="min" [max]="max" [(value)]="fecIni" (keydown)="filtrarReporteKeydown($event)">
                                <kendo-datepicker-messages today="Hoy">
                                </kendo-datepicker-messages>
                            </kendo-datepicker>
                            <span *ngIf="errorFiltro.fecIni" class="form-error">
                                Debes ingresar Fecha Desde
                            </span>
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label>Período Hasta:</label>
                            <kendo-datepicker class="form-control" placeholder="Ingresa" [disabled]="true" [(value)]="fecFin" (keydown)="filtrarReporteKeydown($event)">
                                <kendo-datepicker-messages today="Hoy">
                                </kendo-datepicker-messages>
                            </kendo-datepicker>
                            <span *ngIf="errorFiltro.fecFin" class="form-error">
                                Debes ingresar Fecha Hasta
                            </span>
                        </div>

                        <div class="col-12 col-md-1 form-group mt-1">
                            <a (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Filtrar Resultados" class="btn btn-primary" style="margin-top:20px;">
                                <img src="../assets/img/busca.png" class="icon-md">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container">
            <div class="col-lg-10 offset-lg-1 col-12 mt-2">
                <div class="row">
                    <div class="col-12 col-md-5 mt-2">
                        <h2>Resumen cartola:</h2>
                    </div>
                    <div class="col-12 offset-md-3 col-md-4 d-inline-flex">
                        <div class="dropdown">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                <img src="assets/img/empresa-blanco.svg" class="icon-md mr-1">Generacion Masiva
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" (click)="openModal(refModalConfirmacion)" href="javascript:void">
                                    <a class="btn btn-primary btn-sm"><img src="assets/img/agregar-big.png"
                                        class="icon-sm"></a> Generar y enviar PDF
                                    </a>
                                    <a class="dropdown-item" (click)="iniciarEnvioMasivoExcel()" href="javascript:void">
                                        <a class="btn btn-primary btn-sm"><img src="assets/img/agregar-big.png"
                                        class="icon-sm"></a> Generar Excel
                                    </a>
                            </div>
                        </div>
                        <a (click)="pdf2.saveAs('Cartola SAP.pdf')" data-toggle="tooltip" ngbTooltip="Exportar a PDF" class="ml-2 btn btn-primary">
                            <img src="../assets/img/download.png" class="icon-md">Exportar PDF
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 col-12 mt-2 mx-auto">
                <div class="row">
                    <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2">
                            <div class="col-md-6 my-2 text-left">
                                <h3 class="text-bold">Empresa</h3>
                                <h2>{{empresaSeleccionado.Nombre}}</h2>
                            </div>
                            <div class="col-md-3 offset-3">
                                <h3 class="margin-1-30 text-bold">Fecha</h3>
                                <span class="badge badge-primary">{{fechaActual}}</span>
                            </div>
                            <div class="col-2">
                                <label>RUT</label>
                                <p>{{empresaSeleccionado.Rut}}</p>
                            </div>
                            <div class="col-2">
                                <label>N° Empresa</label>
                                <p>{{empresaSeleccionado.Codigo}}</p>
                            </div>
                            <div class="col-3">
                                <label>Grupo empresas</label>
                                <p>{{empresaSeleccionado.Holding}}</p>
                            </div>
                            <div class="col-3">
                                <label>Direccion</label>
                                <p>{{empresaSeleccionado.Direccion}}</p>
                            </div>
                            <div class="col-2">
                                <label>Comuna</label>
                                <p>{{empresaSeleccionado.Comuna}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2">
                            <div class="col-md-12 text-left my-2">
                                <h2>Datos intermediación</h2>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-between">
                                <label>% Remuneración imponible declarada</label>
                                <p>${{cartolaCompleta.unoAnterior | number | miles}}</p>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-between">
                                <label>% Comisión Administración</label>
                                <p>{{cartolaCompleta.comision.comisionPromedio | number | miles}}%</p>
                            </div>
                            <div class="col-3 d-flex flex-column justify-content-between">
                                <label>Tope de Inversión <br>DNC</label>
                                <p>${{cartolaCompleta.topeInvDnc | number | miles}}</p>
                            </div>
                            <div class="col-3 d-flex flex-column justify-content-between">
                                <label>Tope V&T <br>Normal</label>
                                <p>${{cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal | number | miles}}</p>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-between">
                                <label>Tope V&T Excedentes</label>
                                <p>${{cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal | number | miles}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <h2 class="mt-auto">Resumen Capacitación</h2>
                    </div>
                    <div class="col-12 mt-2" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.aportesCollapse = !cartolaCompleta.aportesCollapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.aportesCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.aportesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-bold">Aportes</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="cartolaCompleta.aportesCollapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.aportesCollapse != cartolaCompleta.aportesCollapse">
                                <div class="col-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="text-bold text-center">N° Aporte</td>
                                                <td class="text-bold text-center">Fecha Aporte</td>
                                                <td class="text-bold text-center">Sucursal</td>
                                                <td class="text-bold text-center">Tipo de Transaccion</td>
                                                <td class="text-bold text-center">Banco</td>
                                                <td class="text-bold text-center">Monto en $</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="cartolaCompleta.aportes.aportes.length > 0;else sinAportes">
                                                <tr *ngFor="let aporte of cartolaCompleta.aportes.aportes">
                                                    <td>{{aporte.codigo}}</td>
                                                    <td>{{aporte.fecha | customDate:'DD/MM/YYYY'}}</td>
                                                    <td>{{aporte.sucursal}}</td>
                                                    <td>{{aporte.tipotransaccion}}</td>
                                                    <td>{{aporte.banco}}</td>
                                                    <td>$ {{aporte.monto | number | miles}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #sinAportes>
                                                <tr>
                                                    <td colspan="6" class="text-center">Sin Aportes para el periodo seleccionado</td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4" style="border-radius: 15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.ctaExcedentesCollapse = !cartolaCompleta.ctaExcedentesCollapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.ctaExcedentesCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.ctaExcedentesCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-bold">Cuenta Excedentes</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{cartolaCompleta.ctaExcedentes | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="cartolaCompleta.ctaExcedentesCollapse ? 'collapse' : 'show'">
                            <div class="row mt-2">
                                <div class="col-6 text-bold">Saldo inicial</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión Cursos Cap. Excedentes</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión Cursos Cap. Excedentes Reparto</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión V&T</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row mt-2 pb-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-6 text-bold">Total intermediado</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.ctaExcedentesInter | number | miles}}</div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-6 text-bold">Saldo V&T hasta la fecha</div>
                                <div class="col-6 text-bold text-right">${{(cartolaCompleta.saldoVyTExcedentesTotal > 0 ? cartolaCompleta.saldoVyTExcedentesTotal : 0) | number | miles}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4" style="border-radius: 15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="cartolaCompleta.ctaNormalyRepartoCollapse = !cartolaCompleta.ctaNormalyRepartoCollapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="cartolaCompleta.ctaNormalyRepartoCollapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="cartolaCompleta.ctaNormalyRepartoCollapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-bold">Cuenta Normal y Reparto</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{cartolaCompleta.ctaNormalyReparto | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="cartolaCompleta.ctaNormalyRepartoCollapse ? 'collapse' : 'show'">
                            <div class="row mt-2">
                                <div class="col-6 text-bold">Aportes recibidos a la fecha</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Comisión por administración</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.ctaNormalyRepartoCom | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión en V&T Cta. normal</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invVyTNormal.invVyTNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6 text-bold">Saldo disponible para la intermediación</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.ctaNormalyRepartoDisp | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión cursos Cap Cuenta Normal</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invCtaNormal.invCtaNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6">Inversión cursos Cap Cuenta Reparto</div>
                                <div class="col-6 text-right">$ {{cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row mt-2 pb-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-6 text-bold">Total intermediado</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.ctaNormalYRepartoInter | number | miles}}</div>
                            </div>
                            <div class="row mt-2 pb-2" style="border-bottom:1px solid #ccc;">
                                <div class="col-6 text-bold">Saldo disponible para cuenta normal y reparto</div>
                                <div class="col-6 text-bold text-right">$ {{cartolaCompleta.ctaNormalyReparto | number | miles}}</div>
                            </div>
                            <div class="row mt-2 pb-2">
                                <div class="col-6 text-bold">Monto Minimo próximo Aporte + Comisión</div>
                                <div class="col-6 text-bold text-right">$ {{(cartolaCompleta.ctaNormalyReparto >= 0 ? 0 : cartolaCompleta.ctaNormalyRepartoMinimo) | number | miles}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-5 mt-4">
                        <h2 class="mt-auto">Detalles Cursos Contratados</h2>
                    </div>
                    <div class="col-12 mt-2" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.normal.collapse = !detalleCursos.normal.collapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.normal.collapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="detalleCursos.normal.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <span class="d-inline-flex flex-wrap align-items-start">
                                    <h2 class="text-bold">Normal</h2>
                                    <span class="ml-2 mt-1 badge badge-primary">{{detalleCursos.normal.cursos.length}}</span>
                                </span>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{detalleCursos.normal.invCtaNormal | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="detalleCursos.normal.collapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.normal.collapse = detalleCursos.normal.collapse">
                                <div class="col-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="text-bold text-center">N° ACC</td>
                                                <td class="text-bold text-center">Folio Sence</td>
                                                <td style="word-wrap: break-word; max-width: 150px;" class="text-bold text-center">Nombre Curso</td>
                                                <td class="text-bold text-center">Fecha inicio</td>
                                                <td class="text-bold text-center">Fecha Termino</td>
                                                <td class="text-bold text-center">V&T</td>
                                                <td class="text-bold text-center">Costo</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="detalleCursos.normal.cursos.length > 0;else sinDatosNormal">
                                                <tr *ngFor="let curso of detalleCursos.normal.cursos">
                                                    <td class="text-center">{{curso.nroac}}</td>
                                                    <td class="text-center">{{curso.foliosence}}</td>
                                                    <td style="word-wrap: break-word; max-width: 150px;">{{curso.nombrecurso}}</td>
                                                    <td class="text-center">{{curso.fechainicio | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">{{curso.fechatermino | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">$ {{(curso.costoviaticootic + curso.costotrasladootic) | number | miles}}</td>
                                                    <td class="text-center">$ {{(curso.costocursootic + curso.costocursoempresa) | number | miles}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #sinDatosNormal>
                                                <tr>
                                                    <td colspan="7" class="text-center">Sin cursos para mostrar</td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.reparto.collapse = !detalleCursos.reparto.collapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.reparto.collapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="detalleCursos.reparto.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <span class="d-inline-flex flex-wrap align-items-start">
                                    <h2 class="text-bold">Reparto</h2>
                                    <span class="ml-2 mt-1 badge badge-primary">{{detalleCursos.reparto.cursos.length}}</span>
                                </span>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{detalleCursos.reparto.invCtaRep | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="detalleCursos.reparto.collapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.reparto.collapse = detalleCursos.reparto.collapse">
                                <div *ngIf="detalleCursos.reparto.cursos.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.normal.collapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="detalleCursos.reparto.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="text-bold text-center">N° ACC</td>
                                                <td class="text-bold text-center">Folio Sence</td>
                                                <td style="word-wrap: break-word; max-width: 150px;" class="text-bold text-center">Nombre Curso</td>
                                                <td class="text-bold text-center">Fecha inicio</td>
                                                <td class="text-bold text-center">Fecha Termino</td>
                                                <td class="text-bold text-center">V&T</td>
                                                <td class="text-bold text-center">Costo</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="detalleCursos.reparto.cursos.length > 0;else sinDatosReparto">
                                                <tr *ngFor="let curso of detalleCursos.reparto.cursos">
                                                    <td class="text-center">{{curso.nroac}}</td>
                                                    <td class="text-center">{{curso.foliosence}}</td>
                                                    <td style="word-wrap: break-word; max-width: 150px;">{{curso.nombrecurso}}</td>
                                                    <td class="text-center">{{curso.fechainicio | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">{{curso.fechatermino | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">$ {{(curso.costoviaticootic + curso.costotrasladootic) | number | miles}}</td>
                                                    <td class="text-center">$ {{(curso.costocursootic + curso.costocursoempresa) | number | miles}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #sinDatosReparto>
                                                <tr>
                                                    <td colspan="7" class="text-center">Sin cursos para mostrar</td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.excedentes.collapse = !detalleCursos.excedentes.collapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.reparto.collapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="detalleCursos.reparto.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <span class="d-inline-flex flex-wrap align-items-start">
                                    <h2 class="text-bold">Excedentes</h2>
                                    <span class="ml-2 mt-1 badge badge-primary">{{detalleCursos.excedentes.cursos.length}}</span>
                                </span>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{detalleCursos.excedentes.invCtaExc | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="detalleCursos.excedentes.collapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.excedentes.collapse = detalleCursos.excedentes.collapse">
                                <div class="col-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="text-bold text-center">N° ACC</td>
                                                <td class="text-bold text-center">Folio Sence</td>
                                                <td style="word-wrap: break-word; max-width: 150px;" class="text-bold text-center">Nombre Curso</td>
                                                <td class="text-bold text-center">Fecha inicio</td>
                                                <td class="text-bold text-center">Fecha Termino</td>
                                                <td class="text-bold text-center">V&T</td>
                                                <td class="text-bold text-center">Costo</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="detalleCursos.excedentes.cursos.length > 0;else sinDatosExcedentes">
                                                <tr *ngFor="let curso of detalleCursos.excedentes.cursos">
                                                    <td class="text-center">{{curso.nroac}}</td>
                                                    <td class="text-center">{{curso.foliosence}}</td>
                                                    <td style="word-wrap: break-word; max-width: 150px;">{{curso.nombrecurso}}</td>
                                                    <td class="text-center">{{curso.fechainicio | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">{{curso.fechatermino | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">$ {{(curso.costoviaticootic + curso.costotrasladootic) | number | miles}}</td>
                                                    <td class="text-center">$ {{(curso.costocursootic + curso.costocursoempresa) | number | miles}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #sinDatosExcedentes>
                                                <tr>
                                                    <td colspan="7" class="text-center">Sin cursos para mostrar</td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" style="border-radius:15px;border:1px solid #ccc;">
                        <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.excedentesReparto.collapse = !detalleCursos.excedentesReparto.collapse">
                            <div class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.excedentesReparto.collapse ? 'Ver más' : 'Ver menos'">
                                <img [src]="detalleCursos.excedentesReparto.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                            </div>
                            <div class="col-md-6">
                                <span class="d-inline-flex flex-wrap align-items-start">
                                    <h2 class="text-bold">Excedentes Reparto</h2>
                                    <span class="ml-2 mt-1 badge badge-primary">{{detalleCursos.excedentesReparto.cursos.length}}</span>
                                </span>
                            </div>
                            <div class="col-md-5 text-right">
                                <div class="row">
                                    <div class="col-6 mt-1 text-right">
                                        <h4>Saldo Actual:</h4>
                                    </div>
                                    <div class="col-6">
                                        <h2 class="text-bold">$ {{detalleCursos.excedentesReparto.invCtaExcRep | number | miles}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="detalleCursos.excedentesReparto.collapse ? 'collapse' : 'show'">
                            <div class="row p-2" style="border-bottom:1px solid #ccc;" (click)="detalleCursos.excedentesReparto.collapse = detalleCursos.excedentesReparto.collapse">
                                <div *ngIf="detalleCursos.excedentesReparto.cursos.length > 0" class="text-center" style="width:30px;" data-toggle="tooltip" [ngbTooltip]="detalleCursos.excedentesReparto.collapse ? 'Ver más' : 'Ver menos'">
                                    <img [src]="detalleCursos.excedentesReparto.collapse ? '../assets/img/plus-icon.png' : '../assets/img/minus-icon.png'" class="icon-sm">
                                </div>
                                <div class="col-12">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="text-bold text-center">N° ACC</td>
                                                <td class="text-bold text-center">Folio Sence</td>
                                                <td style="word-wrap: break-word; max-width: 150px;" class="text-bold text-center">Nombre Curso</td>
                                                <td class="text-bold text-center">Fecha inicio</td>
                                                <td class="text-bold text-center">Fecha Termino</td>
                                                <td class="text-bold text-center">V&T</td>
                                                <td class="text-bold text-center">Costo</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="detalleCursos.excedentesReparto.cursos.length > 0;else sinDatosExcedentesReparto">
                                                <tr *ngFor="let curso of detalleCursos.excedentesReparto.cursos">
                                                    <td class="text-center">{{curso.nroac}}</td>
                                                    <td class="text-center">{{curso.foliosence}}</td>
                                                    <td style="word-wrap: break-word; max-width: 150px;">{{curso.nombrecurso}}</td>
                                                    <td class="text-center">{{curso.fechainicio | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">{{curso.fechatermino | customDate:'DD/MM/YYYY'}}</td>
                                                    <td class="text-center">$ {{(curso.costoviaticootic + curso.costotrasladootic) | number | miles}}</td>
                                                    <td class="text-center">$ {{(curso.costocursootic + curso.costocursoempresa) | number | miles}}</td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #sinDatosExcedentesReparto>
                                                <tr>
                                                    <td colspan="7" class="text-center">Sin cursos para mostrar</td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-4 mb-5 float-right text-right">
                        <a [routerLink]="['/home-reportes']" class="btn btn-primary ml-2" style="margin-right:-15px;" data-toggle="tooltip" ngbTooltip="Volver atrás"><img src="../assets/img/volver.png" class="icon-md mr-1">Volver</a>
                        <!-- <a href="#" class="btn btn-primary"><img src="../assets/img/verr.png" class="icon-md"> Ver Detalles</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<div style="position: absolute; left: -10000px; top: 0;">
    <kendo-pdf-export #pdf2 paperSize="A4" margin="1cm" [scale]="0.53" [landscape]="true">
        <ng-container [ngTemplateOutlet]="pdf"></ng-container>
    </kendo-pdf-export>
</div>


<ng-template #pdf>
    <div style="margin-bottom: 200px;" class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <h2 class="text-uppercase text-bold">Cartola Empresa</h2>
                    <div class="row">
                        <div class="col-1 text-bold">AÑ&nbsp;O</div>
                        <div class="col-3">2023</div>
                    </div>
                </div>
                <div class="col-4">
                    <img style="max-height: 60px;" class="img-fluid float-right" src="assets/img/logo-otic-2018.png" alt="">
                </div>
            </div>
            <div class="row p-3 mt-2 border border-dark">
                <div class="col-6">
                    <div class="row">
                        <div class="col-2 text-uppercase text-bold">Empresa</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Nombre}}</div>
                    </div>
                    <div class="row">
                        <div class="col-2 text-uppercase text-bold">Direccion</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Direccion}}</div>
                    </div>
                    <div class="row">
                        <div class="col-2 text-uppercase text-bold">Comuna</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Comuna}}</div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2 text-uppercase text-bold">Atencion</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{destinatario.nombre}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-3 text-uppercase text-bold">Grupo empresas</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Holding}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3 text-uppercase text-bold">N° Empresa</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Codigo}}</div>
                    </div>
                    <div class="row">
                        <div class="col-3 text-uppercase text-bold">R.U.T</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{empresaSeleccionado.Rut}}</div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-3 text-uppercase text-bold">Fecha</div>
                        <div class="col-1">:</div>
                        <div class="col-6 text-uppercase text-bold">{{fechaActual}}</div>
                    </div>
                </div>
            </div>
    
            <div class="row p-3 mt-2 border border-dark">
                <div class="col-12">
                    <h4 class="text-uppercase text-bold">Datos intermediacion</h4>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="row mt-3">
                                <div class="col-6 text-uppercase">1% Remuneración Actualizada Año Anterior</div> 
                                <div class="col-1">:</div>
                                <div class="col-5">$ {{cartolaCompleta.unoAnterior | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-uppercase">% Comision por Administracion</div> 
                                <div class="col-1">:</div>
                                <div class="col-5">{{cartolaCompleta.comision.comisionPromedio | number | miles}}%</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6 text-uppercase">Tope V&T Normal</div>
                                <div class="col-1">:</div>
                                <div class="col-5">$ {{cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-uppercase">Tope V&T Excedentes</div>
                                <div class="col-1">:</div>
                                <div class="col-5">$ {{cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal | number | miles}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-3 mt-2 border border-dark">
                <div class="col-12">
                    <h4 class="text-uppercase text-bold">Datos Ejecutivo</h4>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6 text-uppercase">Ejecutivo</div> 
                                <div class="col-1">:</div>
                                <div class="col-5">{{contacto.nombre}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-uppercase">Teléfono</div> 
                                <div class="col-1">:</div>
                                <div class="col-5">{{contacto.telefono | telefono}}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6 text-uppercase">Cargo</div> 
                                <div class="col-1">:</div>
                                <div class="col-5">{{contacto.tipo}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-uppercase">mail</div>
                                <div class="col-1">:</div>
                                <div class="col-5">{{contacto.mail}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex mt-2">
                <div class="w-50 mr-5">
                    <div class="row h-100">
                        <div class="col-12 p-3 h-100 border border-dark">
                            <h4 class="text-uppercase text-bold mb-3">Cuenta Excedentes</h4>
                            <div class="row mb-2">
                                <div class="col-7 text-bold">Saldo Inicial Cuenta de Excedentes</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion Cursos Cap. Excedentes</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion Cursos Cap. Excedentes Reparto</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invCtaExcedentesReparto.invCtaExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion Cursos Cap. Exced. Comp. Labolares</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ 0</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion en V&T Excedentes</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 mb-2 text-bold">Total Intermediado</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaExcedentesInter | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 mb-2 text-bold">Saldo Disponible Cuenta Excedentes</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaExcedentes | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 text-bold">Saldo V&T en Excedentes a la fecha</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{(cartolaCompleta.saldoVyTExcedentesTotal > 0 ? cartolaCompleta.saldoVyTExcedentesTotal : 0) | number | miles}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <div class="row h-100">
                        <div class="col-12 p-3 border border-dark">
                            <h4 class="text-uppercase text-bold mb-3">Cuenta Normal y Reparto del añ&nbsp;o</h4>
                            <div class="row mb-2">
                                <div class="col-7 text-bold">Aporte recibidos a la fecha</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Comision por Administracion</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaNormalyRepartoCom | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion en V&T Cta. Normal</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invVyTNormal.invVyTNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 mb-2 text-bold">Saldo disponible para la intermediacion</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaNormalyRepartoDisp | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion Cursos Cap. Cta Normal</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invCtaNormal.invCtaNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7">Inversion Cursos Cap. Cta Reparto</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.invCtaNormalReparto.invCtaNormalTotal | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 mb-2 text-bold">Total intermediado</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaNormalYRepartoInter | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 text-bold">Saldo disponible para Cuenta Normal y Reparto</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{cartolaCompleta.ctaNormalyReparto | number | miles}}</div>
                            </div>
                            <div class="row">
                                <div class="col-7 text-bold">Monto Minimo proximo Aporte + Comision</div>
                                <div class="col-1">:</div>
                                <div class="col-4 text-right">$ {{(cartolaCompleta.ctaNormalyReparto > 0 ? 0 : cartolaCompleta.ctaNormalyRepartoMinimo ) | number | miles}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row p-3 mt-2 border border-dark">
                <div class="col-12">
                    <h4 class="text-uppercase text-bold">Aportes</h4>
                </div>
                <div class="col-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">N° Aporte</td>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">Fecha Aporte</td>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">Sucursal</td>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">Tipo Transaccion</td>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">Banco</td>
                                <td style="background-color: white; color:black;" class="border border-dark text-uppercase text-bold text-center">Monto en $</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let aporte of cartolaCompleta.aportes.aportes">
                                <td class="font-weight-normal border border-dark text-center">{{aporte.codigo}}</td>
                                <td class="font-weight-normal border border-dark text-center">{{aporte.fecha | customDate:'DD/MM/YYYY'}}</td>
                                <td class="font-weight-normal border border-dark text-center">{{aporte.sucursal}}</td>
                                <td class="font-weight-normal border border-dark text-center">{{aporte.tipotransaccion}}</td>
                                <td class="font-weight-normal border border-dark text-center">{{aporte.banco}}</td>
                                <td class="font-weight-normal border border-dark text-right">$ {{aporte.monto | number | miles}}</td>
                            </tr>
                            <tr>
                                <td colspan="5" class="border border-dark text-center">Total</td>
                                <td class="border border-dark text-right">$ {{cartolaCompleta.aporteImputado.aporteImputadoTotal | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="page container-fluid mt-5 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <h2 class="text-uppercase text-bold">Detalle Cursos intermediados</h2>
                    <div class="row">
                        <div class="col-1 text-bold">AÑ&nbsp;O</div>
                        <div class="col-3">2023</div>
                    </div>
                </div>
                <div class="col-4">
                    <img style="max-height: 60px;" class="img-fluid float-right" src="assets/img/logo-otic-2018.png" alt="">
                </div>
            </div>
            <div class="row mt-4 p-3 border border-dark">
                <div class="col-12"><h4 class="text-uppercase text-bold">Normal</h4></div>
                <div class="col-12">
                    <table class="table bordered border-dark">
                        <thead>
                            <tr>
                                <th colspan="6" style="background-color: white; color:black; border: 1px solid black;border-top: 0px; border-left: 0px;"></th>
                                <th colspan="4" style="background-color: white; color:black" class="border border-dark text-center">OTIC</th>
                                <th colspan="1" style="background-color: white; color:black" class="border border-dark text-center">EMPRESA</th>
                                <th style="background-color: white;color:black;border: 1px solid black; border-top: 0px; border-right: 0px;" class="border border-dark"></th>
                            </tr>
                            <tr>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° ACC</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° PV</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">Folio SENCE</th>
                                <th style="width:100px;max-width:115px;max-height:70px;background-color:white; color:black" class="text-center border border-dark">Nombre Curso</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Inicio</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Termino</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Curso</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Viatico</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Traslado</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr *ngFor="let curso of detalleCursos.normal.cursos">
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.nroac}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.pv | customNumber}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.foliosence}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif; word-wrap:break-word" class="border border-dark">{{curso.nombrecurso | slice:0:9}}...</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechainicio | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechafin | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costocursootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoviaticootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costotrasladootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoempresa | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{(curso.costootic + curso.costoempresa) | number | miles}}</td>
                                </tr>
                            </ng-container>
                            <ng-template>
                                <tr>
                                    <td class="text-center">
    
                                    </td>
                                </tr>
                            </ng-template>
                            <tr>
                                <td colspan="6" class="text-bold text-center border border-dark">Total Cuenta Normal</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.normal.totales.cursootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.normal.totales.viaticootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.normal.totales.trasladootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.normal.totales.cursootic + detalleCursos.normal.totales.viaticootic + detalleCursos.normal.totales.trasladootic) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.normal.totales.cursoempresa + detalleCursos.normal.totales.viaticoempresa + detalleCursos.normal.totales.trasladoempresa) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.normal.totales.total | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="page row mt-4 p-3 border border-dark">
                <div class="col-12"><h4 class="text-uppercase text-bold">Reparto</h4></div>
                <div class="col-12">
                    <table class="table bordered border-dark">
                        <thead>
                            <tr>
                                <th colspan="6" style="background-color: white; color:black; border: 1px solid black;border-top: 0px; border-left: 0px;"></th>
                                <th colspan="4" style="background-color: white; color:black" class="border border-dark text-center">OTIC</th>
                                <th colspan="1" style="background-color: white; color:black" class="border border-dark text-center">EMPRESA</th>
                                <th style="background-color: white;color:black;border: 1px solid black; border-top: 0px; border-right: 0px;" class="border border-dark"></th>
                            </tr>
                            <tr>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° ACC</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° PV</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">Folio SENCE</th>
                                <th style="width:100px;max-width:115px;max-height:70px;background-color:white; color:black" class="text-center border border-dark">Nombre Curso</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Inicio</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Termino</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Curso</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Viatico</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Traslado</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr *ngFor="let curso of detalleCursos.reparto.cursos">
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.nroac}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.pv | customNumber}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.foliosence}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif; word-wrap:break-word" class="border border-dark">{{curso.nombrecurso | slice:0:9}}...</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechainicio | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechafin | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costocursootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoviaticootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costotrasladootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoempresa | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{(curso.costootic + curso.costoempresa) | number | miles}}</td>
                                </tr>
                            </ng-container>
                            <ng-template>
                                <tr>
                                    <td class="text-center">
    
                                    </td>
                                </tr>
                            </ng-template>
                            <tr>
                                <td colspan="6" class="text-bold text-center border border-dark">Total Cuenta Reparto</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.reparto.totales.cursootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.reparto.totales.viaticootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.reparto.totales.trasladootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.reparto.totales.cursootic + detalleCursos.reparto.totales.viaticootic + detalleCursos.reparto.totales.trasladootic) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.reparto.totales.cursoempresa + detalleCursos.reparto.totales.viaticoempresa + detalleCursos.reparto.totales.trasladoempresa) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.reparto.totales.total | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="page row mt-4 p-3 border border-dark">
                <div class="col-12"><h4 class="text-uppercase text-bold">Excedentes</h4></div>
                <div class="col-12">
                    <table class="table bordered border-dark">
                        <thead>
                            <tr>
                                <th colspan="6" style="background-color: white; color:black; border: 1px solid black;border-top: 0px; border-left: 0px;"></th>
                                <th colspan="4" style="background-color: white; color:black" class="border border-dark text-center">OTIC</th>
                                <th colspan="1" style="background-color: white; color:black" class="border border-dark text-center">EMPRESA</th>
                                <th style="background-color: white;color:black;border: 1px solid black; border-top: 0px; border-right: 0px;" class="border border-dark"></th>
                            </tr>
                            <tr>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° ACC</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° PV</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">Folio SENCE</th>
                                <th style="width:100px;max-width:115px;max-height:70px;background-color:white; color:black" class="text-center border border-dark">Nombre Curso</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Inicio</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Termino</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Curso</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Viatico</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Traslado</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr *ngFor="let curso of detalleCursos.excedentes.cursos">
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.nroac}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.pv | customNumber}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.foliosence}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif; word-wrap:break-word" class="border border-dark">{{curso.nombrecurso | slice:0:9}}...</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechainicio | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechafin | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costocursootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoviaticootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costotrasladootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoempresa | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{(curso.costootic + curso.costoempresa) | number | miles}}</td>
                                </tr>
                            </ng-container>
                            <ng-template>
                                <tr>
                                    <td class="text-center">
    
                                    </td>
                                </tr>
                            </ng-template>
                            <tr>
                                <td colspan="6" class="text-bold text-center border border-dark">Total Cuenta Excedentes</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentes.totales.cursootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentes.totales.viaticootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentes.totales.trasladootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.excedentes.totales.cursootic + detalleCursos.excedentes.totales.viaticootic + detalleCursos.excedentes.totales.trasladootic) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.excedentes.totales.cursoempresa + detalleCursos.excedentes.totales.viaticoempresa + detalleCursos.excedentes.totales.trasladoempresa) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentes.totales.total | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="page row mt-4 p-3 border border-dark">
                <div class="col-12"><h4 class="text-uppercase text-bold">Excedentes Reparto</h4></div>
                <div class="col-12">
                    <table class="table bordered border-dark">
                        <thead>
                            <tr>
                                <th colspan="6" style="background-color: white; color:black; border: 1px solid black;border-top: 0px; border-left: 0px;"></th>
                                <th colspan="4" style="background-color: white; color:black" class="border border-dark text-center">OTIC</th>
                                <th colspan="1" style="background-color: white; color:black" class="border border-dark text-center">EMPRESA</th>
                                <th style="background-color: white;color:black;border: 1px solid black; border-top: 0px; border-right: 0px;" class="border border-dark"></th>
                            </tr>
                            <tr>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° ACC</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">N° PV</th>
                                <th style="width:100px;background-color: white; color:black;" class="text-center border border-dark">Folio SENCE</th>
                                <th style="width:100px;max-width:115px;max-height:70px;background-color:white; color:black" class="text-center border border-dark">Nombre Curso</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Inicio</th>
                                <th style="width: 111px;background-color: white; color:black;" class="text-center border border-dark">Fecha Termino</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Curso</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Viatico</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Traslado</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                                <th style="width: 115px;background-color: white; color:black;" class="text-center border border-dark">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr *ngFor="let curso of detalleCursos.excedentesReparto.cursos">
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.nroac}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.pv | customNumber}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="text-center border border-dark">{{curso.foliosence}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif; word-wrap:break-word" class="border border-dark">{{curso.nombrecurso | slice:0:9}}...</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechainicio | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-center border border-dark">{{curso.fechafin | customDate: 'DD/MM/YYYY'}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costocursootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoviaticootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costotrasladootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costootic | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{curso.costoempresa | number | miles}}</td>
                                    <td style="font-family: 'Montserrat', sans-serif;" class="font-weight-normal text-right border border-dark">$ {{(curso.costootic + curso.costoempresa) | number | miles}}</td>
                                </tr>
                            </ng-container>
                            <ng-template>
                                <tr>
                                    <td class="text-center">
    
                                    </td>
                                </tr>
                            </ng-template>
                            <tr>
                                <td colspan="6" class="text-bold text-center border border-dark">Total Cuenta Excedentes Reparto</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentesReparto.totales.cursootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentesReparto.totales.viaticootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentesReparto.totales.trasladootic | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.excedentesReparto.totales.cursootic + detalleCursos.excedentesReparto.totales.viaticootic + detalleCursos.excedentesReparto.totales.trasladootic) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{(detalleCursos.excedentesReparto.totales.cursoempresa + detalleCursos.excedentesReparto.totales.viaticoempresa + detalleCursos.excedentesReparto.totales.trasladoempresa) | number | miles}}</td>
                                <td class="text-right border border-dark">$ {{detalleCursos.excedentesReparto.totales.total | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="page row mt-4 p-3">
                <div class="col-12">
                    <table class="table border border-dark">
                        <thead>
                            <tr style="visibility: collapse;">
                                <th colspan="6" style="background-color: white; color:white; border: 1px solid black;border-top: 0px; border-left: 0px;"></th>
                                <th colspan="4" style="background-color: white; color:white" class="border border-dark text-center">OTIC</th>
                                <th colspan="1" style="background-color: white; color:white" class="border border-dark text-center">EMPRESA</th>
                                <th style="background-color: white;color:white;border: 1px solid black; border-top: 0px; border-right: 0px;" class="border border-dark"></th>
                            </tr>
                            <tr style="visibility: collapse;">
                                <th style="width:100px!important;background-color: white; color:white;" class="text-center border border-dark">N° ACC</th>
                                <th style="width:100px!important;background-color: white; color:white;" class="text-center border border-dark">N° PV</th>
                                <th style="width:100px!important;background-color: white; color:white;" class="text-center border border-dark">Folio SENCE</th>
                                <th style="width:100px!important;background-color: white; color:white;" class="text-center border border-dark">Nombre Curso</th>
                                <th style="width:110px!important;background-color: white; color:white;" class="text-center border border-dark">Fecha Inicio</th>
                                <th style="width:110px!important;background-color: white; color:white;" class="text-center border border-dark">Fecha Termino</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Curso</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Viatico</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Traslado</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Total</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Total</th>
                                <th style="width:115px!important;background-color: white; color:white;" class="text-center border border-dark">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border border-dark text-uppercase font-weight-bold text-centerr" colspan="6">Total Cursos Contratados</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.cursootic + detalleCursos.reparto.totales.cursootic + detalleCursos.excedentes.totales.cursootic + detalleCursos.excedentesReparto.totales.cursootic) | number | miles}}</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.viaticootic + detalleCursos.reparto.totales.viaticootic + detalleCursos.excedentes.totales.viaticootic + detalleCursos.excedentesReparto.totales.viaticootic) | number | miles}}</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.trasladootic + detalleCursos.reparto.totales.trasladootic + detalleCursos.excedentes.totales.trasladootic + detalleCursos.excedentesReparto.totales.trasladootic) | number | miles}}</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.totalotic + detalleCursos.reparto.totales.totalotic + detalleCursos.excedentes.totales.totalotic + detalleCursos.excedentesReparto.totales.totalotic) | number | miles}}</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.totalempresa + detalleCursos.reparto.totales.totalempresa + detalleCursos.excedentes.totales.totalempresa + detalleCursos.excedentesReparto.totales.totalempresa) | number | miles}}</td>
                                <td class="font-weight-bold text-right border border-dark">$ {{(detalleCursos.normal.totales.total + detalleCursos.reparto.totales.total + detalleCursos.excedentes.totales.total + detalleCursos.excedentesReparto.totales.total) | number | miles}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #refModalConfirmacion let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Confirmación</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="modal-body">
        <ng-container *ngIf="empresasSeleccionadas.length == 0;else seleccion">
            <p>¿Esta seguro que desea enviar la cartola a todos los clientes activados?</p>
        </ng-container>
        <ng-template #seleccion>
            <p>¿Esta seguro que desea enviar la cartola a los siguientes clientes?:</p>
            <ul class="list-group">
                <li *ngFor="let empresa of empresasSeleccionadas" class="list-group-item">{{empresa}}</li>
            </ul>
        </ng-template>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close(false)">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="modal.close(true);">Aceptar</button>
    </div>
</ng-template>