import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { of } from 'rxjs';
import * as moment from 'moment';
import { ClienteAuditoria } from 'otic-cliente-auditoria';
// Services
import { UserLoginService } from '../../services/user-login.service';
import { ClienteComercialService } from '../../services/cliente-comercial.service';
// Models
import { EmpresasSucursalModel } from 'src/app/models/empresasSucursal.model';
// Shared
import { environment } from '../../../environments/environment';
import { GLOBALS } from '../../shared/globals.shared';
import { ConsolidadoService } from '../../services/consolidado.service';
var RepHistorialParticipanteComponent = /** @class */ (function () {
    function RepHistorialParticipanteComponent(spinner, toastr, router, localStorageService, clienteComercialService, userLoginService, consolidadoService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.router = router;
        this.localStorageService = localStorageService;
        this.clienteComercialService = clienteComercialService;
        this.userLoginService = userLoginService;
        this.consolidadoService = consolidadoService;
        this.urlGestion = environment.url_gestor;
        this.min = null;
        this.max = null;
        this.fechaActual = [
            new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
            (new Date().getMonth() + 1).toString().length === 1 ?
                '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
            new Date().getFullYear()
        ].join('/');
        this.sinPVs = false;
        this.userData = {};
        this.empresasSucursales = [];
        this.parsedData = [];
        this.checkedKeys = [];
        this.searchTerm = '';
        this.empresasSucursalSeleccionadas = [];
        this.prevEmpresasSucursalSeleccionadas = [];
        this.fecIni = null;
        this.fecFin = null;
        this.rutParticipante = null;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false,
            rutParticipante: false
        };
        this.consolidado = [];
        this.permisosModulosAcciones = {};
        this.checkNoEnviarCorreo = false;
        /**
         * Trae los hijos del árbol
         */
        this.children = function (dataItem) { return of(dataItem.items); };
        /**
         * Valida si tiene hijos el árbol
         */
        this.hasChildren = function (dataItem) { return !!dataItem.items; };
        console.log('RepAportesImputadosComponent: constructor');
    }
    /**
     * OnInit Event
     */
    RepHistorialParticipanteComponent.prototype.ngOnInit = function () {
        console.log('RepAportesImputadosComponent: OnInit');
        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    };
    RepHistorialParticipanteComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');
            if (this.permisosModulosAcciones.REPHISTPART) {
                this.initListadoEmpresas();
            }
            else {
                this.toastr.error("No est\u00E1s autorizado para ingresar a esta p\u00E1gina", GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }
        }
    };
    /**
     * Inicializa el listado de Empresas
     */
    RepHistorialParticipanteComponent.prototype.initListadoEmpresas = function () {
        var _this = this;
        this.spinner.show();
        var sucursalesAutorizadas = this.permisos.map(function (item) { return item.data.sucursalCodigo; }).join(',');
        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var empresas, index, codigoEmpresa, rama, _i, empresas_1, empresa;
                return tslib_1.__generator(this, function (_a) {
                    if (result.rows.length > 0) {
                        empresas = result.rows.map(function (empresa) { return new EmpresasSucursalModel(empresa); });
                        index = 1;
                        codigoEmpresa = void 0;
                        rama = void 0;
                        for (_i = 0, empresas_1 = empresas; _i < empresas_1.length; _i++) {
                            empresa = empresas_1[_i];
                            if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    value: empresa.codigoEmpresa,
                                    items: []
                                };
                            }
                            else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoEmpresa
                                        }]
                                };
                            }
                            else {
                                rama.items.push({
                                    text: empresa.sucursal,
                                    value: empresa.codigoEmpresa
                                });
                            }
                            if (index === empresas.length) {
                                this.empresasSucursales.push(rama);
                            }
                            index++;
                        }
                        this.parsedData = this.empresasSucursales;
                        console.log('this.parsedData', this.parsedData);
                        this.listadoFinal = [{
                                text: 'Seleccionar Todos',
                                value: 'Todos',
                                items: this.parsedData.map(function (x) {
                                    return {
                                        text: x.text,
                                        value: x.value,
                                        items: x.items
                                    };
                                })
                            }];
                        console.log('prueba', this.listadoFinal);
                    }
                    else {
                        this.toastr.warning("No hay informaci\u00F3n de Empresas / Sucursales para Reportes", "Advertencia");
                    }
                    this.spinner.hide();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            this.toastr.warning("No hay Empresas / Sucursales en Cliente Comercial", "Advertencia");
            this.spinner.hide();
        }
    };
    Object.defineProperty(RepHistorialParticipanteComponent.prototype, "checkableSettings", {
        /**
         * Crea las Propiedades del TreeView
         */
        get: function () {
            return {
                checkChildren: true,
                checkParents: true,
                mode: 'multiple',
                checkOnClick: false
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Evento al presionar una tecla en la búsqueda
     * @param value Valor Ingresado
     */
    RepHistorialParticipanteComponent.prototype.onkeyup = function (value) {
        this.parsedData = this.search(this.empresasSucursales, value);
        if (this.parsedData.length > 0) {
            this.listadoFinal = [{
                    text: 'Seleccionar Todos',
                    value: 'Todos',
                    items: this.parsedData.map(function (x) {
                        return {
                            text: x.text,
                            value: x.value,
                            items: x.items
                        };
                    })
                }];
            console.log('prueba', this.listadoFinal);
        }
        else {
            this.listadoFinal = [];
        }
    };
    /**
     * Búsqueda
     * @param items Items
     * @param term Término (Palabra)
     */
    RepHistorialParticipanteComponent.prototype.search = function (items, term) {
        var _this = this;
        return items.reduce(function (acc, item) {
            if (_this.contains(item.text, term)) {
                acc.push(item);
            }
            else if (item.items && item.items.length > 0) {
                var newItems = _this.search(item.items, term);
                if (newItems.length > 0) {
                    acc.push({ text: item.text, items: newItems });
                }
            }
            return acc;
        }, []);
    };
    /**
     * Compara el Texto y el Término ingresado
     * @param text Texto
     * @param term Término (Palabra)
     */
    RepHistorialParticipanteComponent.prototype.contains = function (text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };
    /**
     * Crea el array de empresas
     * @param itemLookup TreeItemLookup
     */
    RepHistorialParticipanteComponent.prototype.handleChecking = function (itemLookup) {
        this.empresasSucursalSeleccionadas = [];
        if (this.checkedKeys.find(function (x) { return x === itemLookup.item.index; }) !== undefined) {
            if (itemLookup.children.length > 0) {
                var _loop_1 = function (hijo) {
                    var aux = hijo.item.dataItem.items;
                    if (aux) {
                        if (hijo.item.dataItem.items.length > 0) {
                            var x = void 0;
                            var _loop_3 = function () {
                                var valor = {
                                    value: x.value,
                                    text: x.text
                                };
                                this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor.value || x.text !== valor.text; });
                            };
                            for (var _i = 0, _a = hijo.item.dataItem.items; _i < _a.length; _i++) {
                                x = _a[_i];
                                _loop_3();
                            }
                        }
                        else {
                            var valor_1 = {
                                value: hijo.item.dataItem.value,
                                text: hijo.item.dataItem.text
                            };
                            this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_1.value || x.text !== valor_1.text; });
                        }
                    }
                    else {
                        var valor_2 = {
                            value: hijo.item.dataItem.value,
                            text: hijo.item.dataItem.text
                        };
                        this_1.prevEmpresasSucursalSeleccionadas = this_1.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_2.value || x.text !== valor_2.text; });
                    }
                };
                var this_1 = this;
                for (var _i = 0, _a = itemLookup.children; _i < _a.length; _i++) {
                    var hijo = _a[_i];
                    _loop_1(hijo);
                }
            }
            else {
                var valor_3 = {
                    value: itemLookup.item.dataItem.value,
                    text: itemLookup.item.dataItem.text
                };
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_3.value || x.text !== valor_3.text; });
            }
        }
        else {
            if (itemLookup.children.length > 0) {
                var _loop_2 = function (hijo) {
                    var aux = hijo.item.dataItem.items;
                    if (aux) {
                        if (hijo.item.dataItem.items.length > 0) {
                            var x = void 0;
                            var _loop_4 = function () {
                                var valor = {
                                    value: x.value,
                                    text: x.text
                                };
                                this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor.value || x.text !== valor.text; });
                                this_2.prevEmpresasSucursalSeleccionadas.push(valor);
                            };
                            for (var _i = 0, _a = hijo.item.dataItem.items; _i < _a.length; _i++) {
                                x = _a[_i];
                                _loop_4();
                            }
                        }
                        else {
                            var valor_4 = {
                                value: hijo.item.dataItem.value,
                                text: hijo.item.dataItem.text
                            };
                            this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_4.value || x.text !== valor_4.text; });
                            this_2.prevEmpresasSucursalSeleccionadas.push(valor_4);
                        }
                    }
                    else {
                        var valor_5 = {
                            value: hijo.item.dataItem.value,
                            text: hijo.item.dataItem.text
                        };
                        this_2.prevEmpresasSucursalSeleccionadas = this_2.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_5.value || x.text !== valor_5.text; });
                        this_2.prevEmpresasSucursalSeleccionadas.push(valor_5);
                    }
                };
                var this_2 = this;
                for (var _b = 0, _c = itemLookup.children; _b < _c.length; _b++) {
                    var hijo = _c[_b];
                    _loop_2(hijo);
                }
            }
            else {
                var valor_6 = {
                    value: itemLookup.item.dataItem.value,
                    text: itemLookup.item.dataItem.text
                };
                this.prevEmpresasSucursalSeleccionadas = this.prevEmpresasSucursalSeleccionadas.filter(function (x) { return x.value !== valor_6.value || x.text !== valor_6.text; });
                this.prevEmpresasSucursalSeleccionadas.push(valor_6);
            }
        }
        console.log('this.prevEmpresasSucursalSeleccionadas: ', this.prevEmpresasSucursalSeleccionadas);
        for (var _d = 0, _e = this.prevEmpresasSucursalSeleccionadas; _d < _e.length; _d++) {
            var a = _e[_d];
            this.empresasSucursalSeleccionadas.push(a.value);
        }
        this.inicializaFechas();
    };
    /**
     * Busca las fechas de Inicio y Fin de las PV al cambiar la selección
     */
    RepHistorialParticipanteComponent.prototype.inicializaFechas = function () {
        var _this = this;
        this.fecIni = null;
        this.fecFin = null;
        this.sinPVs = false;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false,
            rutParticipante: false
        };
        if (this.empresasSucursalSeleccionadas.length > 0) {
            var rutsEmpresa = this.empresasSucursalSeleccionadas.join(',');
            this.spinner.show();
            this.consolidadoService.obtenerFechasPVs(rutsEmpresa).subscribe(function (result) {
                _this.spinner.hide();
                if (result.minanio === null) {
                    _this.sinPVs = true;
                }
                else {
                    _this.min = new Date(result.minanio.substring(0, 19));
                    _this.max = new Date(result.maxanio.substring(0, 19));
                    _this.fecIni = _this.min;
                    _this.fecFin = _this.max;
                }
            }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            if (this.empresasSucursales.length > 0) {
                this.errorFiltro.empresaSucursal = true;
            }
        }
    };
    RepHistorialParticipanteComponent.prototype.validarRut = function (rutCompleto) {
        rutCompleto = rutCompleto.replace('‐', '-');
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
            return false;
        }
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == 'K') {
            digv = 'k';
        }
        return (this.checkDV(rut) == digv);
    };
    RepHistorialParticipanteComponent.prototype.checkDV = function (rutPartNumber) {
        var M = 0;
        var S = 1;
        for (; rutPartNumber; rutPartNumber = Math.floor(rutPartNumber / 10)) {
            S = (S + rutPartNumber % 10 * (9 - M++ % 6)) % 11;
        }
        return S ? S - 1 : 'k';
    };
    RepHistorialParticipanteComponent.prototype.configuraFechas = function () {
        /*
        this.spinner.show();

        this.listFecIni = [];
        this.listFecFin = [];

        for (let z = this.minIni; z <= (this.fecFinDesde !== undefined ? this.fecFinDesde : this.maxIni); z++) {
          this.listFecIni.push(z.toString());
        }

        for (let z = (this.fecIniDesde !== undefined ? this.fecIniDesde : this.minIni); z <= this.maxIni; z++) {
          this.listFecFin.push(z.toString());
        }

        this.spinner.hide();
        */
    };
    /**
     * Filtra el Reporte
     */
    RepHistorialParticipanteComponent.prototype.filtrarReporte = function () {
        var _this = this;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false,
            rutParticipante: false
        };
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        }
        else {
            if (this.fecIni === undefined || this.fecIni === null) {
                this.errorFiltro.fecIni = true;
            }
            if (this.fecFin === undefined || this.fecFin === null) {
                this.errorFiltro.fecFin = true;
            }
            if (this.fecIni === undefined || this.fecIni === null || this.fecFin === undefined || this.fecFin === null) {
                return;
            }
            if (this.rutParticipante === undefined || this.rutParticipante === null || !this.validarRut(this.rutParticipante)) {
                this.errorFiltro.rutParticipante = true;
                return;
            }
        }
        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        this.rutParticipante.replace('‐', '-');
        var splitRut = this.rutParticipante.split('-');
        this.rutParticipante = parseInt(splitRut[0], 10) + '-' + splitRut[1];
        var filtrosReporte = {
            cliComercial: this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            fecIni: moment(this.fecIni).format('L'),
            fecFin: moment(this.fecFin).format('L'),
            rutParticipante: this.rutParticipante,
            usuario: this.userData.rut,
            email: this.userData.email,
            noEnviarCorreo: this.checkNoEnviarCorreo
        };
        this.spinner.show();
        this.consolidadoService.reporteHistorialParticipanteStart(filtrosReporte).subscribe(function (result) {
            console.log("Proceso iniciado");
            _this.accion('Reporte Historial del Participantes', '', 'Genera Reporte para ' + _this.nombreCC);
            _this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
            _this.spinner.hide();
        }, function (error) {
            console.log("No es posible descargar el archivo", error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    RepHistorialParticipanteComponent.prototype.filtrarReporteKeydown = function (event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    };
    RepHistorialParticipanteComponent.prototype.accion = function (modulo, submodulo, acc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.localStorageService.get('idToken')];
                    case 1:
                        token = _a.sent();
                        console.log('token', token);
                        ClienteAuditoria({
                            sistema: 'GCH Reportes',
                            modulo: modulo,
                            submodulo: submodulo ? submodulo : '',
                            usuario: this.userData.rut,
                            token: token,
                            accion: acc
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return RepHistorialParticipanteComponent;
}());
export { RepHistorialParticipanteComponent };
