import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string | number | Date, format?: string,zone?:boolean): string {
    moment.locale('es')
    let date = moment(value)
    if(zone) date = moment.parseZone(value)
    return (date.isValid()) ? date.format(format) : '';
  }

}
