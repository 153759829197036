import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'angular-web-storage';
import { UserLoginService } from '../../services/user-login.service';
import { environment } from '../../../environments/environment';
import { RolService } from 'src/app/services/rol.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.shared';
import { CognitoUtilService } from '../../services/cognito.service';
var AuthComponent = /** @class */ (function () {
    function AuthComponent(cognitoUtil, router, spinner, localStorageService, userLoginService, rolService, modalService, toastr, authService) {
        this.cognitoUtil = cognitoUtil;
        this.router = router;
        this.spinner = spinner;
        this.localStorageService = localStorageService;
        this.userLoginService = userLoginService;
        this.rolService = rolService;
        this.modalService = modalService;
        this.toastr = toastr;
        this.authService = authService;
        this.empresas = [];
        this.clientesComercial = [];
        this.navigate = ['/'];
        this.tituloModal = 'Cliente Comercial';
        console.log('AuthComponent: constructor');
    }
    AuthComponent.prototype.ngOnInit = function () {
        this.userLoginService.isAuthenticated(this);
    };
    AuthComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var permisosModulosAcciones;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('auth: verificacion sesion');
                        if (!isLoggedIn) return [3 /*break*/, 1];
                        permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
                        if (JSON.stringify(permisosModulosAcciones) === '{}') {
                            this.login();
                        }
                        else {
                            this.persistirCliComSeleccionadoEntreSistemas();
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.login()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthComponent.prototype.persistirCliComSeleccionadoEntreSistemas = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, paramCliCom, paramEmp, accessToken, idToken, rtoken, ud, ci, idx, _a, queryParams, userData, userDataAux, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        params = new URLSearchParams(this.router.url.split('#')[1]);
                        this.navigate = [params.get('navigate') || '/'];
                        paramCliCom = params.get('clicom');
                        paramEmp = params.get('emp');
                        accessToken = params.get('access_token');
                        idToken = params.get('id_token');
                        rtoken = params.get('rt');
                        ud = params.get('ud');
                        ci = params.get('ci');
                        this.localStorageService.set('ud', ud);
                        this.localStorageService.set('ci', ci);
                        idx = this.router.url.lastIndexOf('navigate');
                        if (idx != -1) {
                            _a = this.router.url.slice(idx).split('&'), queryParams = _a.slice(1);
                            queryParams.forEach(function (param) {
                                var _a;
                                var k = param.slice(0, param.indexOf('='));
                                var v = param.slice(param.indexOf('=') + 1);
                                _this.queryParams = tslib_1.__assign({}, _this.queryParams, (_a = {}, _a[k] = v, _a));
                            });
                        }
                        if (!paramCliCom) return [3 /*break*/, 2];
                        userData = null;
                        if (params.has('ud')) {
                            userData = JSON.parse(atob(params.get('ud')));
                        }
                        this.localStorageService.set('userData', {
                            rut: userData.rut + '-' + userData.dv,
                            nombre: userData.nombre,
                            email: userData.email,
                            now: +new Date(),
                        }, 0, 's');
                        return [4 /*yield*/, this.validaToken(accessToken, idToken, userData, rtoken, paramCliCom, paramEmp)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 2:
                        userDataAux = this.localStorageService.get('userData');
                        console.log('else paramCliCom');
                        if (!userDataAux.empresas) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigate(this.navigate, {
                                queryParams: tslib_1.__assign({}, this.queryParams)
                            })];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.validarClienteComercial(userDataAux.email);
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _b.sent();
                        console.error(error_1);
                        this.router.navigate(['/']);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AuthComponent.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, accessToken, idToken, rtoken, ud, paramCliCom, paramEmp, userData, idx, _a, queryParams;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.spinner.show()];
                    case 1:
                        _b.sent();
                        localStorage.clear();
                        params = new URLSearchParams(this.router.url.split('#')[1]);
                        this.navigate = [params.get('navigate') || '/'];
                        accessToken = params.get('access_token');
                        idToken = params.get('id_token');
                        rtoken = params.get('rt');
                        ud = params.get('ud');
                        paramCliCom = params.get('clicom');
                        paramEmp = params.get('emp');
                        this.localStorageService.set('ud', ud);
                        this.localStorageService.set('ci', params.get('ci') ? params.get('ci') : environment.CLIENT_ID);
                        userData = null;
                        idx = this.router.url.lastIndexOf('navigate');
                        if (idx != -1) {
                            _a = this.router.url.slice(idx).split('&'), queryParams = _a.slice(1);
                            queryParams.forEach(function (param) {
                                var _a;
                                var k = param.slice(0, param.indexOf('='));
                                var v = param.slice(param.indexOf('=') + 1);
                                _this.queryParams = tslib_1.__assign({}, _this.queryParams, (_a = {}, _a[k] = v, _a));
                            });
                        }
                        if (params.has('ud')) {
                            userData = JSON.parse(atob(params.get('ud')));
                        }
                        return [4 /*yield*/, this.validaToken(accessToken, idToken, userData, rtoken, paramCliCom, paramEmp)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthComponent.prototype.validaToken = function (accessToken, idToken, userData, rtoken, paramCliCom, paramEmp) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.userLoginService.validateToken({
                    access_token: accessToken,
                    id_token: idToken
                }).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log(result);
                                this.localStorageService.set('ci', result.aud);
                                return [4 /*yield*/, this.setCookies(idToken, accessToken, result, userData, rtoken)];
                            case 1:
                                _a.sent();
                                this.validarClienteComercial(userData.email, paramCliCom, paramEmp);
                                return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    console.log(error);
                    window.location.href = environment.URL_SIGNOUT + "?callback=" + environment.URL_AUTH + "&code=" + environment.CLIENT_ID;
                });
                return [2 /*return*/];
            });
        });
    };
    AuthComponent.prototype.validarClienteComercial = function (email, paramCliCom, paramEmp) {
        if (paramCliCom === void 0) { paramCliCom = null; }
        if (paramEmp === void 0) { paramEmp = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.obtenerClienteComercial(email).then(function () { })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.authService.cargarAccionesActivas(this.usuarioId).then(function () { })];
                    case 2:
                        _a.sent();
                        if (!(this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined)) return [3 /*break*/, 4];
                        console.log('Buscar Empresas cuando tiene la opcion habilitada');
                        return [4 /*yield*/, this.obtenerEmpresasPorUsuarioEmail(email).then(function () { })];
                    case 3:
                        _a.sent();
                        this.tituloModal = 'Empresa';
                        if (this.empresas.length === 1) {
                            this.clienteComercialSeleccionado = this.clientesComercial.find(function (c) { return c.codigo === _this.empresas[0].codigo; });
                        }
                        _a.label = 4;
                    case 4:
                        if (paramCliCom) {
                            paramCliCom = Number(paramCliCom);
                            if (this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined) {
                                // tslint:disable-next-line: max-line-length
                                this.clienteComercialSeleccionado = this.empresas.find(function (c) { return c.nombre.split(' - ')[0].toString() === paramEmp.toString(); });
                            }
                            else {
                                this.clienteComercialSeleccionado = this.clientesComercial.find(function (c) { return c.codigo === paramCliCom; });
                            }
                        }
                        if (this.clienteComercialSeleccionado) {
                            this.confirmarClienteComercial();
                        }
                        else {
                            if (this.clientesComercial.length > 1) {
                                this.spinner.hide();
                                // tslint:disable-next-line: max-line-length
                                this.modalService.open(this.modalSeleccionarClienteComercial, { size: 'lg', backdrop: 'static', keyboard: false });
                            }
                            else if (this.clientesComercial.length === 1) {
                                this.confirmarClienteComercial();
                            }
                            else {
                                throw new Error('No hay Cliente/s Comercial');
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthComponent.prototype.setCookies = function (idToken, accessToken, infoUserCognito, infoUser, refreshToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyPrefix, idTokenKey, accessTokenKey, lastUserKey, refreshTokenKey;
            return tslib_1.__generator(this, function (_a) {
                keyPrefix = "CognitoIdentityServiceProvider." + infoUserCognito.aud;
                idTokenKey = keyPrefix + "." + infoUserCognito['cognito:username'] + ".idToken";
                accessTokenKey = keyPrefix + "." + infoUserCognito['cognito:username'] + ".accessToken";
                lastUserKey = keyPrefix + ".LastAuthUser";
                refreshTokenKey = keyPrefix + "." + infoUserCognito['cognito:username'] + ".refreshToken";
                localStorage.setItem(idTokenKey, idToken);
                localStorage.setItem(accessTokenKey, accessToken);
                localStorage.setItem(lastUserKey, infoUserCognito['cognito:username']);
                localStorage.setItem(refreshTokenKey, refreshToken);
                if (infoUser) {
                    this.localStorageService.set('userData', {
                        rut: infoUser.rut + '-' + infoUser.dv,
                        nombre: infoUser.nombre,
                        email: infoUser.email,
                        now: +new Date()
                    }, 0, 's');
                }
                this.localStorageService.set('idToken', idToken);
                this.localStorageService.set('accessToken', accessToken);
                this.localStorageService.set('refreshToken', refreshToken);
                return [2 /*return*/];
            });
        });
    };
    AuthComponent.prototype.confirmarClienteComercial = function () {
        var _this = this;
        if (this.clienteComercialSeleccionado) {
            this.spinner.show();
            Promise.all([
                this.authService.cargarPermisosCliCom(this.usuarioId, this.clienteComercialSeleccionado.codigo)
            ])
                .then(function () {
                // guarda en storage
                var userData = _this.localStorageService.get('userData');
                userData.usuarioId = _this.usuarioId;
                userData.clientesComercial = _this.clientesComercial;
                userData.empresas = _this.empresas;
                // tslint:disable-next-line: max-line-length
                userData.clienteComercialSeleccionado = _this.clientesComercial.find(function (x) { return x.codigo === _this.clienteComercialSeleccionado.codigo; });
                userData.empresaSeleccionada = _this.clienteComercialSeleccionado;
                _this.localStorageService.set('userData', userData);
                if (_this.clientesComercial.length > 1) {
                    _this.toastr.success("Cliente comercial " + userData.clienteComercialSeleccionado.nombre + " seleccionado");
                }
                _this.modalService.dismissAll();
                _this.router.navigate(_this.navigate, {
                    queryParams: tslib_1.__assign({}, _this.queryParams)
                });
            }).catch(function (error) {
                console.log(error);
                _this.toastr.error('Ocurrio un error al obtener Permisos.');
            }).finally(function () {
                _this.spinner.hide();
            });
        }
        else if (this.clientesComercial.length === 1) {
            Promise.all([
                this.authService.cargarPermisosCliCom(this.usuarioId, this.clientesComercial[0].codigo)
            ])
                .then(function () {
                // guarda en storage
                var userData = _this.localStorageService.get('userData');
                userData.usuarioId = _this.usuarioId;
                userData.clientesComercial = _this.clientesComercial;
                userData.empresas = _this.empresas;
                // tslint:disable-next-line: max-line-length
                userData.clienteComercialSeleccionado = _this.clientesComercial[0];
                userData.empresaSeleccionada = _this.clientesComercial[0];
                _this.localStorageService.set('userData', userData);
                if (_this.clientesComercial.length > 1) {
                    _this.toastr.success("Cliente comercial " + userData.clienteComercialSeleccionado.nombre + " seleccionado");
                }
                _this.modalService.dismissAll();
                _this.router.navigate(_this.navigate, {
                    queryParams: tslib_1.__assign({}, _this.queryParams)
                });
            }).catch(function (err) {
                _this.toastr.error('Ocurrio un error al obtener Permisos.');
            }).finally(function () {
                _this.spinner.hide();
            });
        }
        else {
            var tipoSeleccion = this.localStorageService.get('PERMISOSMODULOSACCIONES').LOGIN !== undefined ? 'una Empresa' : 'un Cliente Comercial';
            this.toastr.error("Debe seleccionar " + tipoSeleccion);
        }
    };
    AuthComponent.prototype.obtenerEmpresasPorUsuarioEmail = function (usuarioEmail) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rolService.obtenerEmpresasPorUsuarioEmail(usuarioEmail)
                            .subscribe(function (response) {
                            _this.empresas = response.usuario.empresas;
                            resolve();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AuthComponent.prototype.obtenerClienteComercial = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.rolService.obtenerClientesComercialPorUsuarioEmail(email)
                                        .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        return tslib_1.__generator(this, function (_a) {
                                            this.usuarioId = response.usuario.id;
                                            this.clientesComercial = response.usuario.clicom;
                                            resolve();
                                            return [2 /*return*/];
                                        });
                                    }); }, function (error) {
                                        console.log(error);
                                        _this.spinner.hide();
                                        _this.toastr.error('Ocurrio un error al obtener Cliente Comercial.');
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    return AuthComponent;
}());
export { AuthComponent };
