<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-12 mt-3">
                    <h1>Reporte Historial del Participante</h1>
                </div>
                <div class="col-md-5 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/home-reportes']">Reportes</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Reporte Historial del Participante</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 form-group">
                    <div class="row">
                        <div class="col-12" style="margin-bottom: 25px;">
                            <label>Selecciona una o varias Empresas y/o Sucursales, el Período y el RUT del Participante a consultar, luego presiona la tecla Enter o el botón con el icono de Lupa:</label>
                            <label>(Para poder seleccionar un período o ingresar el RUT del participante, debe seleccionar una Empresa o Sucursal en primer lugar)</label>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 form-group">
                                    <label>Empresas y Sucursales:</label>
                                    <input class="form-control cursor-pointer" data-toggle="collapse" data-target="#buscarEmpresas" (keydown)="filtrarReporteKeydown($event)">
                                    <span style="position:absolute;margin-top:-28px;margin-left:15px;z-index:1000;" data-toggle="collapse" data-target="#buscarEmpresas" class="cursor-pointer">
                                        {{empresasSucursalSeleccionadas.length === 0 ? 'Selecciona' : empresasSucursalSeleccionadas.length === 1 ? '1 Cliente Sucursal seleccionado' : empresasSucursalSeleccionadas.length + ' Clientes Sucursales seleccionados'}}
                                    </span>
                                    <span style="position:absolute;margin-top:-28px;margin-right:30px;z-index:1000;right:0" data-toggle="collapse" data-target="#buscarEmpresas" class="cursor-pointer">
                                        <img src="../assets/img/flecha-abajo-combo.svg"/>
                                    </span>
                                </div>
                                <div class="collapse mx-auto" id="buscarEmpresas" style="border:1px solid #ccc;margin-top:-16px;width:95.5%;border-radius:5px;min-height:100px;transition:1s ease all; overflow-y: auto;">
                                    <div class="row p-2">
                                        <div class="col-12 form-group">
                                            <label>Buscar Empresas y/o Sucursales:</label>
                                            <input [(ngModel)]="searchTerm" #busqueda (keyup)='onkeyup(busqueda.value)' type="text" class="form-control" placeholder="Ingresa RUT o Nombre para filtrar">
                                        </div>
                                        <kendo-treeview [nodes]="listadoFinal" [children]="children" [hasChildren]="hasChildren" textField="text" [kendoTreeViewCheckable]="checkableSettings" [(checkedKeys)]="checkedKeys" (checkedChange)="handleChecking($event)">
                                        </kendo-treeview>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="errorFiltro.empresaSucursal" class="form-error">
                              Debes seleccionar al menos una Empresa o Sucursal.
                            </span>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12 col-md-6 form-group">
                                    <label>Fecha Inicio Desde:</label>
                                    <kendo-datepicker class="form-control" placeholder="Ingresa" [(ngModel)]="fecIni" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <span *ngIf="errorFiltro.fecIni" class="form-error">
                                      Debes seleccionar Fecha Desde
                                  </span>
                                </div>
                                <div class="col-12 col-md-6 form-group">
                                    <label>Fecha Término Hasta:</label>
                                    <kendo-datepicker class="form-control" placeholder="Ingresa" [(ngModel)]="fecFin" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" [min]="min" [max]="max" (keydown)="filtrarReporteKeydown($event)">
                                        <kendo-datepicker-messages today="Hoy">
                                        </kendo-datepicker-messages>
                                    </kendo-datepicker>
                                    <span *ngIf="errorFiltro.fecFin" class="form-error">
                                      Debes seleccionar Fecha Hasta
                                  </span>
                                </div>
                                <div class="col-12 col-md-8 form-group">
                                    <label>RUT Participante</label>
                                    <input type="text" class="form-control" placeholder="Ingrese RUT del participante, ej: 11111111-1" [(ngModel)]="rutParticipante" maxlength="10" [disabled]="empresasSucursalSeleccionadas.length === 0 || sinPVs" />
                                    <span *ngIf="errorFiltro.rutParticipante" class="form-error">
                                        Debe ingresar un RUT válido
                                    </span>
                                </div>
                                <div class="col-12 col-md-2 form-group mt-1">
                                    <a *ngIf="permisosModulosAcciones.REPHISTPART.acciones.includes('REPHISTPART_Acceder')" (click)="filtrarReporte()" data-toggle="tooltip" ngbTooltip="Descargar Reporte" class="btn btn-primary" style="margin-top:20px;">
                                        <img src="../assets/img/busca.png" class="icon-md">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>