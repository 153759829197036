<main>
    <div class="container-fluid mb-4 fondo-gris-ok" style="margin-top:-20px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-12 mt-3 mb-2">
                    <h1>Reportes</h1>
                </div>
                <div class="col-lg-7 col-12">
                    <div aria-label="breadcrumb" class="text-right float-right">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href={{urlGestion}}>Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page"><a>Reportes</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-12 mt-3 mb-3">
                    <h4 class="text-bold">REPORTES CONTABLES</h4>
                    <p>Selecciona un Reporte:</p>
                </div>
                <!-- <div *ngIf="opciones.repCar !== 0" class="col-md-3 col-sm-6 col-12"> -->
                <div *ngIf="permisosModulosAcciones.REPOCART" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-cartola'], 'Reportes Contables', 'Resumen Cartola')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>RESUMEN<br/>CARTOLA</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="permisosModulosAcciones.REPCARTSOC" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-cartola-soc'], 'Reportes Contables', 'Resumen Cartola Sociedad')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>RESUMEN<br/>CARTOLA<br/>SOCIEDAD</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <div class="col-md-3 col-sm-6 col-12">
                    <a href="#" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/detalle-cursos.png" class="icon-lg"></span><br/>
                                    <span>DETALLE CURSOS<br/>COMUNICADOS SENCE</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> -->
                <!-- <div *ngIf="opciones.repApo !== 0" class="col-md-3 col-sm-6 col-12"> -->
                <div *ngIf="permisosModulosAcciones.REPOAPOR" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-aportes-imputados'], 'Reportes Contables', 'Aportes Imputados')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>APORTES<br/>IMPUTADOS</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="permisosModulosAcciones.REPOCARTSAP" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-cartola-sap'], 'Reportes Contables', 'ReporteSAP')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>RESUMEN <br/> CARTOLA <br/>SAP</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <div class="col-md-3 col-sm-6 col-12">
                    <a href="#" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/topes-legales.png" class="icon-lg"></span><br/>
                                    <span>TOPES<br/>LEGALES</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> -->
            </div>

            <div class="row mt-5 mb-5">
                <div class="col-12 mt-3 mb-3">
                    <h4 class="text-bold">REPORTES ESTADÍSTICOS</h4>
                    <p>Selecciona un Reporte:</p>
                </div>
                <!-- <div class="col-md-3 col-sm-6 col-12">
                    <a href="#" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/indicadores-generales.png" class="icon-lg"></span><br/>
                                    <span>INDICADORES<br/>GENERALES</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <a href="#" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/indicadores-por-area-conocimiento.png" class="icon-lg"></span><br/>
                                    <span>INDICADORES POR<br/>ÁREA DE CONOCIMIENTO</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <a href="#" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/historia-participantes.png" class="icon-lg"></span><br/>
                                    <span>HISTORIA<br/>PARTICIPANTES</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> -->
                <div *ngIf="permisosModulosAcciones.REPCURPART" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-cons-cur-part'], 'Reportes Estadísticos', 'Consolidado Cursos y Participantes')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>CONSOLIDADO<br/>CURSOS Y<br/>PARTICIPANTES</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="permisosModulosAcciones.REPCUR" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-cons-cur'], 'Reportes Estadísticos', 'Consolidado Cursos')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>CONSOLIDADO<br/>CURSOS</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="permisosModulosAcciones.REPHISTPART" class="col-md-3 col-sm-6 col-12">
                    <a (click)="redireccionar(['/rep-historial-part'], 'Reportes Estadísticos', 'Historial Participante')" class="row mx-auto reportes-link pt-4 pb-4 blanquito-hover" style="color:#112540;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 titulo-gestion">
                                    <span class="mt-2 mb-3"><img src="../assets/img/home-reportes.png" class="icon-lg"></span><br/>
                                    <span>HISTORIAL<br/>PARTICIPANTE</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>
