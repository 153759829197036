import { Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { HomeReportesComponent } from './components/home-reportes/home-reportes.component';
import { RepCartolaComponent } from './components/rep-cartola/rep-cartola.component';
import { RepCartolaSAPComponent } from './components/rep-cartola-sap/rep-cartola-sap.component';
import { RepCartolaSociedadComponent } from './components/rep-cartola-sociedad/rep-cartola-sociedad.component';
import { RepAportesImputadosComponent } from './components/rep-aportes-imputados/rep-aportes-imputados.component';
import { RepConsolidadoCursosPartComponent } from './components/rep-consolidado-cursos-part/rep-consolidado-cursos-part.component';
import { RepConsolidadoCursosComponent } from './components/rep-consolidado-cursos/rep-consolidado-cursos.component';
import { AuthGuard } from './_guards';
import { RepHistorialParticipanteComponent } from './components/rep-historial-participante/rep-historial-participante.component';
import { DescargasComponent } from './components/descargas/descargas.component';
var ɵ0 = { redirect: true };
var appRoutes = [
    { path: 'auth', component: AuthComponent },
    { path: 'home-reportes', component: HomeReportesComponent, canActivate: [AuthGuard] },
    // Reportes Contables
    { path: 'rep-cartola', component: RepCartolaComponent, canActivate: [AuthGuard] },
    { path: 'rep-cartola-sap', component: RepCartolaSAPComponent, canActivate: [AuthGuard] },
    { path: 'rep-cartola-soc', component: RepCartolaSociedadComponent, canActivate: [AuthGuard] },
    { path: 'rep-aportes-imputados', component: RepAportesImputadosComponent, canActivate: [AuthGuard] },
    // Reportes Estadísticos
    { path: 'rep-cons-cur-part', component: RepConsolidadoCursosPartComponent, canActivate: [AuthGuard] },
    { path: 'rep-cons-cur', component: RepConsolidadoCursosComponent, canActivate: [AuthGuard] },
    { path: 'rep-historial-part', component: RepHistorialParticipanteComponent, canActivate: [AuthGuard] },
    // Descarga de archivos
    { path: 'descargas', component: DescargasComponent, canActivate: [AuthGuard], data: ɵ0 },
    { path: '', redirectTo: 'home-reportes', pathMatch: 'full' },
    { path: '**', redirectTo: 'home-reportes' }
];
export var appRoutingProvider = [];
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
