import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefono'
})
export class TelefonoPipe implements PipeTransform {

  transform(value: string,): any {
    let valor = value.replace(/\s[aA-zZ]/,'');
    if(/\^+56{d,8}/.test(valor)){
      return valor;
    }else{
      if(valor.length >= 8){
        const telefono = valor.slice(0,8);
        return telefono.length == 8 ? `+56 9 ${telefono}` : `+56 ${telefono.charAt(0)} ${telefono.slice(1)}`
      }else{
        return null
      }
    }
  }

}
