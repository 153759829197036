import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CartolaService = /** @class */ (function () {
    function CartolaService(http) {
        this.http = http;
        console.log('CartolaService: constructor');
    }
    /**
     * Retorna el Reporte de Consolidado Cartola
     * @param filtrosReporte Filtros Ingresados
     */
    CartolaService.prototype.reporteCartola = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CARTOLA + "/reporteCartola/", body, { headers: headers });
    };
    /**
     * Retorna el Reporte de Consolidado Cartola separado por Sociedad
     * @param filtrosReporte Filtros Ingresados
     */
    CartolaService.prototype.reporteCartolaSociedad = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CARTOLA + "/reporteCartolaSociedad/", body, { headers: headers });
    };
    /**
     * Inicia la generacion de la cartola SAP
     * @param filtrosReporte Filtros Ingresados
     */
    CartolaService.prototype.iniciarCartolaMasiva = function (filtrosReporte) {
        var body = JSON.stringify(filtrosReporte);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CARTOLA + "/iniciarGeneracionMasiva/", body, { headers: headers });
    };
    /**
     * Obtine el destinatario de la cartola SAP
     * @param filtrosReporte Filtros Ingresados
     */
    CartolaService.prototype.obtenerDestinatarioCartola = function (codEmpresa) {
        var body = JSON.stringify(codEmpresa);
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(environment.ENDPOINTS.CARTOLA + "/destinatarioCartola/", body, { headers: headers });
    };
    CartolaService.ngInjectableDef = i0.defineInjectable({ factory: function CartolaService_Factory() { return new CartolaService(i0.inject(i1.HttpClient)); }, token: CartolaService, providedIn: "root" });
    return CartolaService;
}());
export { CartolaService };
