import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { of } from 'rxjs';
// Services
import { ClienteComercialService } from '../../services/cliente-comercial.service';
import { CartolaService } from '../../services/cartola.service';
import { UserLoginService } from '../../services/user-login.service';
// Models
import { EmpresasSucursalModel } from '../../models/empresasSucursal.model';
// Shared
import { GLOBALS, GlobalService } from '../../shared/globals.shared';
import { environment } from 'src/environments/environment';
var RepCartolaSociedadComponent = /** @class */ (function () {
    function RepCartolaSociedadComponent(spinner, toastr, globalService, clienteComercialService, cartolaService, router, localStorageService, userLoginService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.globalService = globalService;
        this.clienteComercialService = clienteComercialService;
        this.cartolaService = cartolaService;
        this.router = router;
        this.localStorageService = localStorageService;
        this.userLoginService = userLoginService;
        this.min = null;
        this.max = null;
        this.urlGestion = environment.url_gestor;
        this.fechaActual = [
            new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
            (new Date().getMonth() + 1).toString().length === 1 ?
                '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
            new Date().getFullYear()
        ].join('/');
        this.empresasSucursales = [];
        this.parsedData = [];
        this.checkedKeys = [];
        this.searchTerm = '';
        this.userData = {};
        this.empresasSucursalSeleccionadas = [];
        this.fecIni = null;
        this.fecFin = null;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false
        };
        this.cartolaCompleta = {
            // Cabecera
            aporteComprometido: 0,
            unoAnterior: 0,
            comision: {
                comisionPromedio: 0,
                comisionCollapse: true,
                comisiones: []
            },
            // Cuenta Normal y Reparto
            ctaNormalyReparto: 0,
            ctaNormalyRepartoCollapse: true,
            aporteImputado: {
                aporteImputadoTotal: 0,
                aporteImputadoCollapse: true,
                aportesImputados: [],
            },
            invCtaNormalyRep: {
                invCtaNormalyRepTotal: 0,
                invCtaNormalyRepCollapse: true,
                subClienteNormalyRep: []
            },
            topeInvVyTNormal: {
                topeInvVyTNormalTotal: 0,
                topeInvVyTNormalCollapse: true,
                topeInvVyTNormales: [],
            },
            invVyTNormal: {
                invVyTNormalTotal: 0,
                invVyTNormalCollapse: true,
                invVyTNormales: [],
            },
            comisionInterm: {
                comisionIntermTotal: 0,
                comisionIntermCollapse: true,
                comisionesIntermediadas: [],
            },
            comisionNoInterm: {
                comisionNoIntermTotal: 0,
                comisionNoIntermCollapse: true,
                comisionesNoIntermediadas: [],
            },
            // Cuenta Excedentes
            ctaExcedentes: 0,
            ctaExcedentesCollapse: true,
            saldoExcedentesLiq: {
                saldoExcedentesLiqTotal: 0,
                saldoExcedentesLiqCollapse: true,
                saldoExcedentesLiqs: []
            },
            invCtaExcedentes: {
                invCtaExcedentesTotal: 0,
                invCtaExcedentesCollapse: true,
                subClienteExcedentes: []
            },
            topeInvVyTExcedentes: {
                topeInvVyTExcedentesTotal: 0,
                topeInvVyTExcedentesCollapse: true,
                topeInvVyTExcedentes: [],
            },
            invVyTExcedentes: {
                invVyTExcedentesTotal: 0,
                invVyTExcedentesCollapse: true,
                invVyTExcedentes: [],
            },
            becasMandato: {
                becasMandatoTotal: 0,
                becasMandatoCollapse: true,
                becasMandatos: [],
            }
        };
        this.permisosModulosAcciones = {};
        /**
         * Trae los hijos del árbol
         */
        this.children = function (dataItem) { return of(dataItem.items); };
        /**
         * Valida si tiene hijos el árbol
         */
        this.hasChildren = function (dataItem) { return !!dataItem.items; };
        console.log('RepCartolaSociedadComponent: constructor');
    }
    RepCartolaSociedadComponent.prototype.ngOnInit = function () {
        console.log('RepCartolaSociedadComponent: OnInit');
        this.userLoginService.isAuthenticated(this);
        this.userData = this.localStorageService.get('userData');
    };
    RepCartolaSociedadComponent.prototype.isLoggedIn = function (message, isLoggedIn) {
        if (isLoggedIn) {
            this.router.events.subscribe(function (evt) {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
            this.permisosModulosAcciones = this.localStorageService.get('PERMISOSMODULOSACCIONES') || {};
            this.permisos = this.localStorageService.get('permisos');
            if (this.permisosModulosAcciones.REPOCART) {
                this.initListadoEmpresas();
                this.configuraFechas();
            }
            else {
                this.toastr.error("No est\u00E1s autorizado para ingresar a esta p\u00E1gina", GLOBALS.TOASTER.TITLE_ERROR);
                this.router.navigate(['/']);
            }
        }
    };
    /**
     * Configura las fechas del formulario
     */
    RepCartolaSociedadComponent.prototype.configuraFechas = function () {
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.min = null;
            this.max = null;
            this.fecIni = null;
            this.fecFin = null;
        }
        if (this.min === null && this.max === null) {
            var fechaActual = new Date();
            this.fecIni = new Date(fechaActual.getFullYear(), 0, 1);
            this.fecFin = new Date(fechaActual.getFullYear(), 11, 31);
            this.min = new Date(2006, 0, 1);
            this.max = this.fecFin;
        }
    };
    /**
     * Configura la fecha de Fin del Calendario
     */
    RepCartolaSociedadComponent.prototype.actualizarFechaFin = function (fecha) {
        if (fecha !== null && fecha !== undefined) {
            this.fecFin = new Date(fecha.getFullYear(), 11, 31);
        }
    };
    /**
     * Inicializa el listado de Empresas
     */
    RepCartolaSociedadComponent.prototype.initListadoEmpresas = function () {
        var _this = this;
        this.spinner.show();
        var sucursalesAutorizadas = this.permisos.map(function (item) { return item.data.sucursalCodigo; }).join(',');
        if (sucursalesAutorizadas) {
            this.clienteComercialService.listarEmpresasSucursales(sucursalesAutorizadas)
                .subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var empresas, index, codigoEmpresa, rama, _i, empresas_1, empresa;
                return tslib_1.__generator(this, function (_a) {
                    if (result.rows.length > 0) {
                        empresas = result.rows.map(function (empresa) { return new EmpresasSucursalModel(empresa); });
                        index = 1;
                        codigoEmpresa = void 0;
                        rama = void 0;
                        for (_i = 0, empresas_1 = empresas; _i < empresas_1.length; _i++) {
                            empresa = empresas_1[_i];
                            if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa === empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    value: empresa.codigoSucursal,
                                    items: []
                                };
                            }
                            else if (codigoEmpresa !== empresa.codigoEmpresa && empresa.codigoEmpresa !== empresa.codigoSucursal) {
                                if (index !== 1) {
                                    this.empresasSucursales.push(rama);
                                }
                                codigoEmpresa = empresa.codigoEmpresa;
                                rama = {
                                    text: empresa.rutRazonSocial,
                                    items: [{
                                            text: empresa.sucursal,
                                            value: empresa.codigoSucursal
                                        }]
                                };
                            }
                            else {
                                rama.items.push({
                                    text: empresa.sucursal,
                                    value: empresa.codigoSucursal
                                });
                            }
                            if (index === empresas.length) {
                                this.empresasSucursales.push(rama);
                            }
                            index++;
                        }
                        this.parsedData = this.empresasSucursales;
                    }
                    else {
                        this.toastr.warning("No hay informaci\u00F3n de Empresas / Sucursales para Reportes", "Advertencia");
                    }
                    this.spinner.hide();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                _this.spinner.hide();
                _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            });
        }
        else {
            this.toastr.warning("No hay Empresas / Sucursales en Cliente Comercial", "Advertencia");
            this.spinner.hide();
        }
    };
    Object.defineProperty(RepCartolaSociedadComponent.prototype, "checkableSettings", {
        /**
         * Crea las Propiedades del TreeView
         */
        get: function () {
            return {
                checkChildren: true,
                checkParents: true,
                mode: 'multiple',
                checkOnClick: false
            };
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Evento al presionar una tecla en la búsqueda
     * @param value Valor Ingresado
     */
    RepCartolaSociedadComponent.prototype.onkeyup = function (value) {
        this.parsedData = this.search(this.empresasSucursales, value);
    };
    /**
     * Búsqueda
     * @param items Items
     * @param term Término (Palabra)
     */
    RepCartolaSociedadComponent.prototype.search = function (items, term) {
        var _this = this;
        return items.reduce(function (acc, item) {
            if (_this.contains(item.text, term)) {
                acc.push(item);
            }
            else if (item.items && item.items.length > 0) {
                var newItems = _this.search(item.items, term);
                if (newItems.length > 0) {
                    acc.push({ text: item.text, items: newItems });
                }
            }
            return acc;
        }, []);
    };
    /**
     * Compara el Texto y el Término ingresado
     * @param text Texto
     * @param term Término (Palabra)
     */
    RepCartolaSociedadComponent.prototype.contains = function (text, term) {
        return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };
    /**
     * Crea el array de empresas
     * @param itemLookup TreeItemLookup
     */
    RepCartolaSociedadComponent.prototype.handleChecking = function (itemLookup) {
        if (this.checkedKeys.find(function (x) { return x === itemLookup.item.index; }) !== undefined) {
            if (itemLookup.children.length > 0) {
                var _loop_1 = function (hijo) {
                    this_1.empresasSucursalSeleccionadas = this_1.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                };
                var this_1 = this;
                for (var _i = 0, _a = itemLookup.children; _i < _a.length; _i++) {
                    var hijo = _a[_i];
                    _loop_1(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
            }
        }
        else {
            if (itemLookup.children.length > 0) {
                var _loop_2 = function (hijo) {
                    this_2.empresasSucursalSeleccionadas = this_2.empresasSucursalSeleccionadas.filter(function (x) { return x !== hijo.item.dataItem.value; });
                    this_2.empresasSucursalSeleccionadas.push(hijo.item.dataItem.value);
                };
                var this_2 = this;
                for (var _b = 0, _c = itemLookup.children; _b < _c.length; _b++) {
                    var hijo = _c[_b];
                    _loop_2(hijo);
                }
            }
            else {
                this.empresasSucursalSeleccionadas = this.empresasSucursalSeleccionadas.filter(function (x) { return x !== itemLookup.item.dataItem.value; });
                this.empresasSucursalSeleccionadas.push(itemLookup.item.dataItem.value);
            }
        }
        this.configuraFechas();
    };
    /**
     * Filtra el Reporte
     */
    RepCartolaSociedadComponent.prototype.filtrarReporte = function () {
        var _this = this;
        this.errorFiltro = {
            empresaSucursal: false,
            fecIni: false,
            fecFin: false
        };
        if (this.empresasSucursalSeleccionadas.length === 0) {
            this.errorFiltro.empresaSucursal = true;
            return;
        }
        else {
            if (this.fecIni === null) {
                this.errorFiltro.fecIni = true;
            }
            if (this.fecFin === null) {
                this.errorFiltro.fecFin = true;
            }
            if (this.fecIni === null || this.fecFin === null) {
                return;
            }
        }
        var fechaIni = [
            this.fecIni.getFullYear(),
            (this.fecIni.getMonth() + 1).toString().length === 1 ?
                '0' + (this.fecIni.getMonth() + 1).toString() : (this.fecIni.getMonth() + 1).toString(),
            this.fecIni.getDate().toString().length === 1 ? '0' + this.fecIni.getDate().toString() : this.fecIni.getDate().toString()
        ].join('-');
        var fechaFin = [
            this.fecFin.getFullYear(),
            (this.fecFin.getMonth() + 1).toString().length === 1 ?
                '0' + (this.fecFin.getMonth() + 1).toString() : (this.fecFin.getMonth() + 1).toString(),
            this.fecFin.getDate().toString().length === 1 ? '0' + this.fecFin.getDate().toString() : this.fecFin.getDate().toString()
        ].join('-');
        this.nombreCC = this.userData.clienteComercialSeleccionado.nombre;
        var filtrosReporte = {
            cliComercial: this.nombreCC,
            empresaSucursal: this.empresasSucursalSeleccionadas.join(','),
            fecIni: fechaIni,
            fecFin: fechaFin
        };
        this.spinner.show();
        this.cartolaService.reporteCartolaSociedad(filtrosReporte).subscribe(function (result) {
            _this.spinner.hide();
            // Aporte Comprometido
            _this.cartolaCompleta.aporteComprometido = 0;
            if (result.aporteComprometido.length > 0) {
                for (var _i = 0, _a = result.aporteComprometido; _i < _a.length; _i++) {
                    var obj = _a[_i];
                    _this.cartolaCompleta.aporteComprometido = _this.cartolaCompleta.aporteComprometido + obj.valor;
                }
            }
            // 1% año anterior
            _this.cartolaCompleta.unoAnterior = 0;
            if (result.datosCierre.rows.length > 0) {
                for (var _b = 0, _c = result.datosCierre.rows; _b < _c.length; _b++) {
                    var obj = _c[_b];
                    _this.cartolaCompleta.unoAnterior = _this.cartolaCompleta.unoAnterior + parseInt(obj.klimk, 10);
                }
            }
            // Comision
            _this.cartolaCompleta.comision.comisionCollapse = true;
            _this.cartolaCompleta.comision.comisiones = [];
            _this.cartolaCompleta.comision.comisionPromedio = 0;
            if (result.datosCierre.rows.length > 0) {
                for (var _d = 0, _e = result.datosCierre.rows; _d < _e.length; _d++) {
                    var obj = _e[_d];
                    _this.cartolaCompleta.comision.comisionPromedio = _this.cartolaCompleta.comision.comisionPromedio + parseInt(obj.tasa_por, 10);
                    _this.cartolaCompleta.comision.comisiones.push(obj);
                }
                _this.cartolaCompleta.comision.comisionPromedio = _this.cartolaCompleta.comision.comisionPromedio / result.datosCierre.rows.length;
            }
            // Aporte Imputado
            _this.cartolaCompleta.aporteImputado.aporteImputadoCollapse = true;
            _this.cartolaCompleta.aporteImputado.aportesImputados = [];
            _this.cartolaCompleta.aporteImputado.aporteImputadoTotal = 0;
            if (result.aporteImputado.length > 0) {
                for (var _f = 0, _g = result.aporteImputado; _f < _g.length; _f++) {
                    var obj = _g[_f];
                    _this.cartolaCompleta.aporteImputado.aporteImputadoTotal =
                        _this.cartolaCompleta.aporteImputado.aporteImputadoTotal + parseInt(obj.aporteimpdisp, 10);
                    _this.cartolaCompleta.aporteImputado.aportesImputados.push(obj);
                }
            }
            // Total Inversión en Capacitación (Normal y Reparto)
            _this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepCollapse = true;
            _this.cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep = [];
            _this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal = 0;
            if (result.subClienteInscripcionesNormalYReparto.length > 0) {
                for (var _h = 0, _j = result.subClienteInscripcionesNormalYReparto; _h < _j.length; _h++) {
                    var obj = _j[_h];
                    _this.cartolaCompleta.invCtaNormalyRep.subClienteNormalyRep.push(obj);
                    _this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal =
                        _this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal +
                            parseInt(obj.inscripciones.cuentaNormal, 10) +
                            parseInt(obj.inscripciones.cuentaReparto, 10) +
                            parseInt(obj.inscripciones.eyccl, 10);
                }
            }
            // Tope Inversión Viáticos y Traslados (Normal y Reparto)
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalCollapse = true;
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales = [];
            _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal = 0;
            if (result.aporteImputado.length > 0) {
                for (var _k = 0, _l = result.aporteImputado; _k < _l.length; _k++) {
                    var obj = _l[_k];
                    var topeNormal = Math.round(parseInt(obj.aporteimpdisp, 10) * 0.1);
                    _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormales.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        tope: topeNormal,
                        sociedad: obj.sociedad
                    });
                    _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal =
                        _this.cartolaCompleta.topeInvVyTNormal.topeInvVyTNormalTotal + topeNormal;
                }
            }
            // Total Inversión Viáticos y Traslados (Normal y Reparto)
            _this.cartolaCompleta.invVyTNormal.invVyTNormalCollapse = true;
            _this.cartolaCompleta.invVyTNormal.invVyTNormales = [];
            _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal = 0;
            if (result.subClienteInscripcionesNormalYReparto.length > 0) {
                for (var _m = 0, _o = result.subClienteInscripcionesNormalYReparto; _m < _o.length; _m++) {
                    var obj = _o[_m];
                    _this.cartolaCompleta.invVyTNormal.invVyTNormales.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        vyt: obj.inscripciones.vyt,
                        sociedad: obj.inscripciones.sociedad
                    });
                    _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal =
                        _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal + parseInt(obj.inscripciones.vyt, 10);
                }
            }
            // Total Comisión sobre Intermediación (Normal y Reparto)
            _this.cartolaCompleta.comisionInterm.comisionIntermCollapse = true;
            _this.cartolaCompleta.comisionInterm.comisionesIntermediadas = [];
            _this.cartolaCompleta.comisionInterm.comisionIntermTotal = 0;
            if (result.subClienteInscripcionesNormalYReparto.length > 0) {
                for (var _p = 0, _q = result.subClienteInscripcionesNormalYReparto; _p < _q.length; _p++) {
                    var obj = _q[_p];
                    /*const comision = this.cartolaCompleta.comision.comisiones.find(x => x.cod_sucursal === obj.sucursal).tasa_por;
                    const invVyt = this.cartolaCompleta.invVyTNormal.invVyTNormales.find(x => x.sucursal === obj.sucursal);
        
                    const valorCurso =
                      parseInt(obj.inscripciones.cuentaNormal, 10) +
                      parseInt(obj.inscripciones.cuentaReparto, 10) +
                      parseInt(obj.inscripciones.eyccl, 10) +
                      (invVyt !== undefined ? parseInt(invVyt.vyt, 10) : 0);
        
                    const tasaComision = comision !== undefined ? comision / 100 : 0;
                    const comisionInterm = Math.round((valorCurso / (1 - tasaComision)) - valorCurso);*/
                    var comision = parseInt(obj.inscripciones.comision, 10);
                    _this.cartolaCompleta.comisionInterm.comisionesIntermediadas.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        comision: comision,
                        sociedad: obj.inscripciones.sociedad
                    });
                    _this.cartolaCompleta.comisionInterm.comisionIntermTotal =
                        _this.cartolaCompleta.comisionInterm.comisionIntermTotal + comision;
                }
            }
            // Total Comisión sobre Saldos no Intevertidos (Normal y Reparto)
            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermCollapse = true;
            _this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas = [];
            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal = 0;
            if (result.subClienteInscripcionesNormalYReparto.length > 0) {
                var _loop_3 = function (obj) {
                    var comision = _this.cartolaCompleta.comision.comisiones.find(function (x) { return x.cod_sucursal === obj.sucursal; }).tasa_por;
                    var aporte = _this.cartolaCompleta.aporteImputado.aportesImputados.find(function (x) { return x.sucursal === obj.sucursal; });
                    var invVyt = _this.cartolaCompleta.invVyTNormal.invVyTNormales.find(function (x) { return x.sucursal === obj.sucursal; });
                    var comisionInv = _this.cartolaCompleta.comisionInterm.comisionesIntermediadas.find(function (x) { return x.sucursal === obj.sucursal; });
                    var comisionNoInterm = Math.round(((aporte !== undefined ? parseInt(aporte.aporteimpdisp, 10) : 0) -
                        (parseInt(obj.inscripciones.cuentaNormal, 10) +
                            parseInt(obj.inscripciones.cuentaReparto, 10) +
                            parseInt(obj.inscripciones.eyccl, 10)) -
                        (invVyt !== undefined ? parseInt(invVyt.vyt, 10) : 0) -
                        (comisionInv !== undefined ? parseInt(comisionInv.comision, 10) : 0)) *
                        (comision !== undefined ? comision / 100 : 0));
                    if (comisionNoInterm > 0) {
                        _this.cartolaCompleta.comisionNoInterm.comisionesNoIntermediadas.push({
                            subCliente: obj.subCliente,
                            comision: comisionNoInterm,
                            sociedad: obj.inscripciones.sociedad
                        });
                        _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal =
                            _this.cartolaCompleta.comisionNoInterm.comisionNoIntermTotal +
                                (comisionNoInterm > 0 ? comisionNoInterm : 0);
                    }
                };
                for (var _r = 0, _s = result.subClienteInscripcionesNormalYReparto; _r < _s.length; _r++) {
                    var obj = _s[_r];
                    _loop_3(obj);
                }
            }
            _this.cartolaCompleta.ctaNormalyRepartoCollapse = true;
            _this.cartolaCompleta.ctaNormalyReparto =
                _this.cartolaCompleta.aporteImputado.aporteImputadoTotal -
                    _this.cartolaCompleta.invCtaNormalyRep.invCtaNormalyRepTotal -
                    _this.cartolaCompleta.invVyTNormal.invVyTNormalTotal -
                    _this.cartolaCompleta.comisionInterm.comisionIntermTotal;
            // Saldo Excedentes Líquido (Excedentes)
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqCollapse = true;
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs = [];
            _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal = 0;
            if (result.datosCierre.rows.length > 0) {
                var _loop_4 = function (obj) {
                    /*const comisionAnterior = result.comisionAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const inversionAnterior = result.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const saldoExcedentes =
                      parseInt(obj.aporteimpdisp, 10) -
                      (inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) -
                      (Math.round((inversionAnterior !== undefined ? parseInt(inversionAnterior.costootic, 10) : 0) *
                      (comisionAnterior !== undefined ? parseInt(comisionAnterior.valor, 10) / 100 : 0)) +
                      (inversionAnterior !== undefined ? parseInt(inversionAnterior.costovyt, 10) : 0));*/
                    var saldoExcedentes = parseInt(obj.exc_liqu, 10);
                    var sociedadExcedentes = result.sociedadCuentaExcedentes.find(function (x) { return x.sucursal === obj.cod_sucursal; });
                    _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqs.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        saldo: saldoExcedentes,
                        sociedad: sociedadExcedentes.sociedad
                    });
                    _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal =
                        _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal + saldoExcedentes;
                };
                for (var _t = 0, _u = result.datosCierre.rows; _t < _u.length; _t++) {
                    var obj = _u[_t];
                    _loop_4(obj);
                }
            }
            // Total Inversión en Capacitación (Excedentes)
            _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesCollapse = true;
            _this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes = [];
            _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal = 0;
            if (result.subClienteInscripcionesExcedentes.length > 0) {
                for (var _v = 0, _w = result.subClienteInscripcionesExcedentes; _v < _w.length; _v++) {
                    var obj = _w[_v];
                    _this.cartolaCompleta.invCtaExcedentes.subClienteExcedentes.push(obj);
                    _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal =
                        _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal +
                            parseInt(obj.inscripciones.cuentaNormal, 10) +
                            parseInt(obj.inscripciones.cuentaReparto, 10) +
                            parseInt(obj.inscripciones.eyccl, 10);
                }
            }
            // Tope Inversión Viáticos y Traslados (Excedentes)
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesCollapse = true;
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes = [];
            _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal = 0;
            if (result.aporteImputadoAnioAnterior.length > 0) {
                var _loop_5 = function (obj) {
                    /*const inversionVyT = result.inscripcionesAnioAnterior.find(x => x.sucursal === obj.sucursal);
                    const topeExcedentes =
                      Math.round(parseInt(obj.aporteimpdisp, 10) * 0.1) - (inversionVyT !== undefined ? inversionVyT.costovyt : 0);*/
                    var datosCierreAnterior = result.datosCierreAnterior.rows.find(function (x) { return x.cod_sucursal === obj.sucursal; });
                    var topeExcedentes = Math.round((parseInt(obj.aporteimpdisp, 10) * 0.1)
                        - parseInt(datosCierreAnterior.vt_costo, 10)
                        - parseInt(datosCierreAnterior.via_tras, 10));
                    _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentes.push({
                        nombreempresa: obj.nombreempresa,
                        nombresucursal: obj.nombresucursal,
                        tope: topeExcedentes,
                        sociedad: obj.sociedad
                    });
                    _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal =
                        _this.cartolaCompleta.topeInvVyTExcedentes.topeInvVyTExcedentesTotal + topeExcedentes;
                };
                for (var _x = 0, _y = result.aporteImputadoAnioAnterior; _x < _y.length; _x++) {
                    var obj = _y[_x];
                    _loop_5(obj);
                }
            }
            // Total Inversión Viáticos y Traslados (Excedentes)
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesCollapse = true;
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes = [];
            _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal = 0;
            if (result.subClienteInscripcionesExcedentes.length > 0) {
                for (var _z = 0, _0 = result.subClienteInscripcionesExcedentes; _z < _0.length; _z++) {
                    var obj = _0[_z];
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentes.push({
                        sucursal: obj.sucursal,
                        subCliente: obj.subCliente,
                        vyt: obj.inscripciones.vyt,
                        sociedad: obj.inscripciones.sociedad
                    });
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal =
                        _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal + parseInt(obj.inscripciones.vyt, 10);
                }
            }
            // Becas Mandato
            _this.cartolaCompleta.becasMandato.becasMandatoCollapse = true;
            _this.cartolaCompleta.becasMandato.becasMandatos = [];
            _this.cartolaCompleta.becasMandato.becasMandatoTotal = 0;
            if (result.becasMandato.length > 0) {
                for (var _1 = 0, _2 = result.becasMandato; _1 < _2.length; _1++) {
                    var obj = _2[_1];
                    _this.cartolaCompleta.becasMandato.becasMandatos.push(obj);
                    _this.cartolaCompleta.becasMandato.becasMandatoTotal = _this.cartolaCompleta.becasMandato.becasMandatoTotal + obj.valor;
                }
            }
            _this.cartolaCompleta.ctaExcedentesCollapse = true;
            _this.cartolaCompleta.ctaExcedentes =
                _this.cartolaCompleta.saldoExcedentesLiq.saldoExcedentesLiqTotal -
                    _this.cartolaCompleta.invCtaExcedentes.invCtaExcedentesTotal -
                    _this.cartolaCompleta.invVyTExcedentes.invVyTExcedentesTotal -
                    _this.cartolaCompleta.becasMandato.becasMandatoTotal;
            console.log(_this.cartolaCompleta);
            if (result.clienteCerrado.toString() === 'No') {
                _this.toastr.warning('Cliente no está cerrado, los valores pueden variar', GLOBALS.TOASTER.TITLE_INFO);
            }
        }, function (error) {
            console.log(error);
            _this.spinner.hide();
            _this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        });
    };
    /**
     * Filtra Reporte al presionar tecla Enter
     * @param event Evento
     */
    RepCartolaSociedadComponent.prototype.filtrarReporteKeydown = function (event) {
        if (event.key === 'Enter') {
            this.filtrarReporte();
        }
    };
    return RepCartolaSociedadComponent;
}());
export { RepCartolaSociedadComponent };
